import { initRollbar, rollbar } from './appRollbar';

if (
  window.threekitconf &&
  window.threekitconf.rollbarPlayerToken &&
  window.threekitVersionInfo
) {
  const rollbarConfig = {
    accessToken: window.threekitconf.rollbarPlayerToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    // https://docs.rollbar.com/docs/source-maps#section-using-source-maps-on-many-domains
    transform(payload: any) {
      const trace = payload.body.trace;
      const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.threekit\.[cd][eo][mv](.*)/;
      if (trace && trace.frames) {
        for (let i = 0; i < trace.frames.length; i++) {
          const filename = trace.frames[i].filename;
          if (filename && filename.match(locRegex)) {
            const m = filename.match(locRegex);
            // Be sure that the minified_url when uploading includes 'dynamichost'
            trace.frames[i].filename = m[1] + '://dynamichost' + m[2];
          }
        }
      }
    },
    payload: {
      environment: window.threekitconf.threekitEnv,
      client: {
        javascript: {
          code_version: window.threekitVersionInfo.version,
          guess_uncaught_frames: true,
          source_map_enabled: true,
        },
      },
    },
    autoInstrument: {
      log: false,
    },
  };
  initRollbar(rollbarConfig);
}

const scripts = Array.from(document.getElementsByTagName('script'));
scripts.forEach(script => {
  if (script.src && script.src.match(/threekit-player/)) {
    __webpack_public_path__ = script.src.split(/threekit-player/)[0];
  }
});

import 'styling';

// disable so 'conf' gets imported first */
/* tslint:disable */
import { threekitEnv, usersApiRoot } from 'conf';
import { ConfigurationType, setFetchProductsForTag } from '@threekit/cas';
import fetchApi, { browserFetchWithAuth } from '@threekit/http-api';
import { AnnotationUpdateFunction } from '@threekit/hub-player';
import { Provider } from '@threekit/react-redux';
import { initStore } from '@threekit/redux-store';
import { unloadElement } from 'lib/player';
import { initializeRootElement } from 'lib/rootElement';
import modules from 'modules';
import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { fetchProductsForTag } from 'sections/assets/assets';
import { fetchConfiguration } from 'sections/configurations/configurations';
import { PlayerClassnames } from 'sections/player/containers/Player';
/* tslint:enable */

declare global {
  interface Window {
    threekitPlayer: (opts: PlayerOpts) => void;
    threekitVersionInfo: VersionInfo;
  }
}

const store = initStore({
  modules,
  fetchApi,
  initialState: {},
});

interface PlayerOpts {
  authToken: string;
  el: HTMLElement;
  assetId?: string;
  productId?: string; // deprecated
  stageId?: string;
  options?: {
    polarAngle?: number;
    minPolarOffset?: number;
    maxPolarOffset?: number;

    azimuthAngle?: number;
    minAzimuthOffset?: number;
    maxAzimuthOffset?: number;

    zoom?: number;
    minZoom?: number;
    maxZoom?: number;
  };
  branch?: string;
  initialConfiguration?: ConfigurationType;
  configuration?: ConfigurationType;
  productConfiguration?: ConfigurationType;
  stageConfiguration?: ConfigurationType;
  showConfigurator?: boolean;
  showAR?: boolean;
  showShare?: boolean;
  useCatalog?: boolean;
  onAnnotationChange?: AnnotationUpdateFunction;
  orgId?: string;
  tools?: string[];
  classnames?: PlayerClassnames;
  display: 'webgl' | 'image';
  placeholder?: string;
  cache?: {
    maxAge: number;
    scope: string;
  };
}

async function loadSplit() {
  const sceneGraph = await import(
    /* webpackChunkName: "player-three" */ '@threekit/scene-graph'
  );
  const hubPlayer = await import(
    /* webpackChunkName: "player-three" */ '@threekit/hub-player'
  );
  const PlayerContainer = await import(
    /* webpackChunkName: "player-three" */ 'sections/player/containers/Player'
  );

  setFetchProductsForTag(fetchProductsForTag);
  const Player = PlayerContainer.default;
  store.addModules(sceneGraph.modules);
  store.addModules(hubPlayer.modules);

  return { sceneGraph, hubPlayer, Player };
}

loadSplit();

async function threekitPlayer(opts: PlayerOpts) {
  const {
    authToken,
    el,
    stageId,
    stageConfiguration,
    initialConfiguration,
    showConfigurator,
    onAnnotationChange,
    showAR,
    classnames,
    showShare,
    display = 'webgl',
    placeholder,
    cache,
  } = opts;
  let { orgId } = opts;

  let assetId = opts.assetId;
  if (opts.productId && !assetId) {
    console.warn('productId is deprecated, please use assetId');
    assetId = opts.productId;
  }

  // const options = opts.options || {};
  const useCatalog = opts.useCatalog == null ? false : opts.useCatalog;

  if (
    !authToken &&
    // @ts-ignore
    window.threekitPlayer.conf.appRoot !== location.origin &&
    location.hostname !== 'localhost'
  )
    throw new Error('authToken is required');
  if (!el) throw new Error('el is required');

  store.setApi(browserFetchWithAuth({ authToken, cache }));

  const { hubPlayer, sceneGraph, Player } = await loadSplit();

  if (!assetId) {
    throw new Error('Unknown assetId');
  }

  await store.dispatch(
    sceneGraph.sceneIO.fetch(assetId, { orgId, useCatalog })
  );

  const state = store.getState();

  const meta = sceneGraph.sceneGraph.getSceneMeta(state, assetId);

  orgId = orgId || meta.orgId;

  if (!orgId) {
    throw new Error('no org found for asset...');
  }

  const { playerSettings } = await store.callApi({
    url: `${usersApiRoot}/orgs/${orgId}`,
    method: 'GET',
  });

  initializeRootElement();

  // Check query param for shortId
  const params = new URLSearchParams(location.search);
  const shortId = params.get('tkcsid');

  const serverConf =
    shortId && (await store.dispatch(fetchConfiguration(shortId)));
  const configuration = serverConf
    ? serverConf.variant
    : opts.configuration || initialConfiguration || {};

  const player = await store.dispatch(
    hubPlayer.addPlayer({
      assetId,
      stageId,
      stageConfiguration,
      configuration,
      threekitEnv,
      onAnnotationChange,
      tools: opts.tools,
      orgId,
      playerEl: el,
      unloadHandlers: [unloadElement],
      useCatalog,
      display,
    })
  );

  const node = sceneGraph.scene.get(store, [assetId]);
  if (node.type !== 'Item' && showShare) {
    console.error(
      'invalid threekit player options: sharing is only available for products, please pass in a product for `assetId` or pass in `false` for `showShare`.'
    );
  }

  ReactDOM.unmountComponentAtNode(el);

  const Cleaner: FunctionComponent = ({ children }) => {
    useEffect(
      () => () => {
        store.dispatch(hubPlayer.removePlayer(player.id));
      },
      []
    );
    return <>{children}</>;
  };

  ReactDOM.render(
    <Cleaner>
      <Provider store={store}>
        <div className="threekit" style={{ height: '100%' }}>
          <Player
            settings={playerSettings}
            player={player}
            showConfigurator={showConfigurator}
            showAR={showAR}
            showShare={showShare && node.type === 'Item'}
            classnames={classnames}
            initialConfiguration={initialConfiguration}
            useCatalog={useCatalog}
            orgId={orgId}
            display={display}
            placeholder={placeholder}
            authToken={authToken}
          />
        </div>
      </Provider>
    </Cleaner>,
    el
  );

  return player.api;
}

window.threekitPlayer = threekitPlayer;

if (window.threekitconf) {
  // @ts-ignore
  window.threekitPlayer.conf = window.threekitconf;
  // delete window.threekitconf;
}

if (window.threekitVersionInfo) {
  // @ts-ignore
  window.threekitPlayer.versionInfo = window.threekitVersionInfo;
  delete window.threekitVersionInfo;
}
