import { ThreekitStore } from '@threekit/redux-store';
import { commentsApiRoot } from 'conf';
import { Map, Record } from 'immutable';
import { cacheFetch } from 'sections/app/cache';

export interface CommentProps {
  id: string;
  content: string;
  attachments: any;
  user?: any; // exchange createdBy to user object
  parentId: string;
  createdBy: string;
  orgId?: string;
  createdAt?: Date;
}

const defaultCommentProps = {
  id: '',
  content: '',
  attachments: [],
  orgId: '',
  parentId: '',
  createdBy: '',
  user: '',
  createdAt: new Date(),
};

export class Comment extends Record(defaultCommentProps)
  implements CommentProps {}

interface CommentStateProps {
  comments: Map<string, Comment[]>;
}

type CommentStateType = Record<CommentStateProps>;

const defaultCommentStateProps: CommentStateProps = {
  comments: Map(),
};

export class CommentState extends Record(defaultCommentStateProps)
  implements CommentStateProps {}

const initialState = new CommentState();

const enum Actions {
  SET_COMMENTS = 'SET_COMMENTS',
  ADD_COMMENT = 'ADD_COMMENT',
}

interface CommentStateProps {
  comments: Map<string, Comment[]>;
}

interface SetCommentProps {
  parentId: string;
  comments: Comment[];
}

interface AddCommentProps {
  parentId: string;
  comment: Comment;
}

const reducer = {
  initialState,
  [Actions.SET_COMMENTS](state: CommentStateType, payload: SetCommentProps) {
    return state.setIn(['comments', payload.parentId], payload.comments);
  },

  [Actions.ADD_COMMENT](state: CommentStateType, payload: AddCommentProps) {
    const comments = state.getIn(['comments', payload.parentId]);
    const newComments = [payload.comment].concat(comments);
    return state.setIn(['comments', payload.parentId], newComments);
  },
};

function setComments(parentId: string, comments: Comment[]) {
  return { type: Actions.SET_COMMENTS, payload: { parentId, comments } };
}

function addComment(parentId: string, comment: Comment) {
  return { type: Actions.ADD_COMMENT, payload: { parentId, comment } };
}

export function getComments(store: ThreekitStore, parentId: string) {
  return store.getIn(['comments', 'comments', parentId]);
}

export function fetchComments(parentId: string) {
  return async (store: ThreekitStore) => {
    const res = await store.dispatch(
      cacheFetch('2h', `${commentsApiRoot}/comments?parentId=${parentId}`, {
        parentId,
      })
    );
    if (res === true) return Promise.resolve(true);
    if (res.error) return Promise.reject(res.error);

    store.dispatch(setComments(parentId, res));
    return Promise.resolve(true);
  };
}

export function postComment(attrs: any, attachments: File[]) {
  const formData = new FormData();
  for (let i in attrs) {
    formData.append(i, attrs[i]);
  }
  for (let i in attachments) {
    formData.append(attachments[i].name, attachments[i]);
  }
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${commentsApiRoot}/comments`,
      body: formData,
      method: 'POST',
    });

    if (res === true) return Promise.resolve(true);
    if (res.error) return Promise.reject(res.error);
    store.dispatch(addComment(attrs.parentId, res));
    return Promise.resolve(true);
  };
}
const publicApi = {
  reducer,
  actions: {},
  selectors: {},
};

export default publicApi;
