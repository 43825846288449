import { ThreekitStore } from '@threekit/redux-store';
import { List } from 'immutable';
import qs from 'query-string';
import { getActiveOrg } from 'sections/orgs/orgs';

export interface PaginationQuery {
  perPage?: number;
  page?: number;
  sort?: string;
}

export interface ResultsWithPageData<T> {
  results: T;
  count: number;
  page: number;
  perPage: number;
  sort: string;
}

export const generateQuery = (url: string, query: any) => {
  return (
    (!query && url) ||
    url + (url.includes('?') ? '&' : '?') + qs.stringify(query)
  );
};

export interface FetchOptions {
  apiRoot: string;
  key: string;
  clazz: any;
  setFn?: Function;
  statePath?: string[];
  query?: { [key: string]: any };
  useOrgId?: boolean;
  stateKey?: string;
}

export const fetchResources = async <T>(
  store: ThreekitStore,
  {
    apiRoot,
    key,
    setFn,
    statePath,
    query,
    clazz,
    useOrgId = true,
    stateKey = 'id',
  }: FetchOptions
): Promise<ResultsWithPageData<T>> => {
  if (useOrgId) {
    const { orgId } = getActiveOrg(store);
    query = { orgId, ...query };
  }

  const url = generateQuery(apiRoot, query);
  const res = await store.callApi({ url });
  if (res.error) return Promise.reject(res.error);

  // separate resources from pagnation data
  const { [key]: resources, ...paginationData } = res;
  if (!setFn || !statePath) {
    return {
      results: List<T>(resources.map((resource: any) => new clazz(resource))),
      ...paginationData,
    };
  }

  await store.dispatch(setFn(resources));

  const state = store.getIn(statePath) || [];

  return {
    // get items from redux store.
    results: List<T>(resources).reduce((acc, resource: any) => {
      const item = state.get(resource[stateKey]);
      return item ? acc.push(item) : acc;
    }, List<T>()),
    // and rest of the pagiantion data (e.g. total)
    ...paginationData,
  };
};

const reflect = (promise: Promise<any>) => {
  return promise.then(
    data => ({ data, status: 'fulfilled' }),
    err => ({ err, status: 'rejected' })
  );
};

// Use a reflect (similar to bluebird) to ensure Promise.all doesn't fail if a call fails
export const reflectPromises = async <T>(promises: Array<Promise<any>>) => {
  return Promise.all(promises.map(reflect)).then(res => {
    const results = res.filter(result => result.status === 'fulfilled');
    return results.reduce(
      (acc, record) => {
        if ('data' in record) {
          if ('results' in record.data) acc = [...acc, ...record.data.results];
          else acc.push(record.data);
        }
        return acc;
      },
      [] as T[]
    );
  });
};
