import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { List, Map, Record } from 'immutable';
import _ from 'lodash';
import queryString from 'query-string';
import { cacheFetch } from 'sections/app/cache';
import { Org, setOrgs } from 'sections/orgs/orgs';
import { filesApiRoot, usersApiRoot } from '../../conf';
import {
  FetchOptions,
  fetchResources,
  PaginationQuery,
  ResultsWithPageData,
  reflectPromises,
} from '../app/pagination';

export interface UserProfile {
  name?: string;
  company?: string;
  city?: string;
  country?: string;
  website?: string;
}

export interface UserProps {
  id: string;
  createdAt: Date;
  username: string;
  profile: UserProfile;
  emails: string[];
  photo?: string;
  super: boolean;
  orgs: Org[];
}

const defaultUserProps: UserProps = {
  id: '',
  createdAt: new Date(),
  username: '',
  profile: {},
  photo: '',
  emails: [],
  super: false,
  orgs: [],
};

export type UserType = Record<UserProps>;

export type UsersMap = Map<string, UserType>;

export class User extends Record(defaultUserProps) implements UserProps {
  public constructor(values?: Partial<UserProps>) {
    values ? super(values) : super();
  }

  get isAnon(): boolean {
    return !this.username || this.username === 'anon';
  }

  get nameWithUsername(): string {
    return this.profile.name
      ? `${this.profile.name} (${this.username})`
      : this.username;
  }
}

const enum Actions {
  SET_USER = 'SET_USER',
  SET_USERS = 'SET_USERS',
  SET_EDITING_USER = 'SET_EDITING_USER',
}

interface UserStateProps {
  user: UserType;
  editingUser: UserType;
  users: UsersMap;
}
type UserStateType = Record<UserStateProps>;

const defaultUserStateProps: UserStateProps = {
  user: new User(),
  editingUser: new User(),
  users: Map(),
};

class UserState extends Record(defaultUserStateProps)
  implements UserStateProps {}

const initialState = new UserState();

const reducer = {
  initialState,
  [Actions.SET_USER](state: UserStateType, user: Partial<UserProps>) {
    return state.set('user', new User(user));
  },
  [Actions.SET_EDITING_USER](state: UserStateType, user: Partial<UserProps>) {
    return state.set('editingUser', new User(user));
  },
  [Actions.SET_USERS](state: UserStateType, users: UserProps[]) {
    const map = users.reduce((acc: UsersMap, user: User) => {
      return acc.set(user.id, new User(user));
    }, state.get('users'));
    return state.set('users', map);
  },
};

export interface CreateUser {
  username: string;
  email: string;
  password: string;
}

export interface SignInUser {
  identifier: string;
  password: string;
}

export interface RequestPasswordReset {
  identifier: string;
}

export interface PasswordReset {
  password: string;
  confirmPassword: string;
}

export function getUser(store: ThreekitStore, id?: string): User {
  return id
    ? store.getIn(['user', 'users', id])
    : store.getIn(['user', 'user']);
}

export function getEditingUser(store: ThreekitStore): User {
  return store.getIn(['user', 'editingUser']);
}

export function setUser(u: Partial<UserProps>) {
  return { type: Actions.SET_USER, payload: u };
}

export function setEditingUser(u: Partial<UserProps>) {
  return { type: Actions.SET_EDITING_USER, payload: u };
}

export function setUsers(users: UserProps[]) {
  return { type: Actions.SET_USERS, payload: users };
}

export function setUserAsync(u: UserProps): ThunkAction<string> {
  return (store: ThreekitStore) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        store.dispatch(setUser(u));
        resolve(u.username);
      }, 1000);
    });
  };
}

export function loadUser(): ThunkAction<UserProps> {
  return async (store: ThreekitStore) => {
    const u = getUser(store);
    if (u.username) return Promise.resolve(u.username);

    const user: any = await store.callApi({
      url: `${usersApiRoot}/users/session`,
    });
    if (!user.username) return Promise.reject('Invalid user session');

    store.dispatch(setUser(user));
    if (user.orgs) store.dispatch(setOrgs(user.orgs));

    return Promise.resolve(user);
  };
}

export function signOut(): ThunkAction<string> {
  return async (store: ThreekitStore) => {
    const res: any = await store.callApi({
      url: `${usersApiRoot}/users/session`,
      method: 'DELETE',
    });
    store.dispatch(setUser({ username: 'anon' }));
    window.location.replace('/'); // some issues with old user state conflicitng when new user is logged in. new page load for logout solves this for now
    return Promise.resolve('anon');
  };
}

export interface SignInResponse {
  username: string;
  isVerified: boolean;
}

export function signIn(attrs: SignInUser): ThunkAction<User> {
  return async (store: ThreekitStore) => {
    try {
      const user: any = await store.callApi({
        url: `${usersApiRoot}/users/session`,
        body: attrs,
        method: 'POST',
      });

      store.dispatch(setUser(user)); // username: res.users[0].username }));
      if (user.orgs) store.dispatch(setOrgs(user.orgs));
      return Promise.resolve(user);
    } catch (err) {
      if (err.status === 401)
        return Promise.reject({ _error: 'Sign in failed' });
      return Promise.reject({ _error: 'Sign in error' });
    }
  };
}

export function createUser(body: CreateUser): ThunkAction<string | any> {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${usersApiRoot}/users`,
      body,
      method: 'POST',
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setUser(res));
    return Promise.resolve(res.username);
  };
}

export function saveUser(
  user: User,
  attrs: Partial<UserProps>
): ThunkAction<string | any> {
  return async (store: ThreekitStore) => {
    const body = user.merge(attrs).toJS();

    const res = await store.callApi({
      url: `${usersApiRoot}/users/${user.id}`,
      body,
      method: 'PUT',
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setUser(body));
    return Promise.resolve(body);
  };
}

export function superSaveUser(
  id: string,
  attrs: Partial<UserProps>
): ThunkAction<string | any> {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${usersApiRoot}/users/${id}/super`,
      body: attrs,
      method: 'PUT',
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setUsers([res]));
    return Promise.resolve(res);
  };
}

export function createVerification(): ThunkAction<string | any> {
  return async (store: ThreekitStore) => {
    try {
      const res: any = await store.callApi({
        url: `${usersApiRoot}/users/verify`,
        method: `POST`,
      });

      // what to do here?
      return Promise.resolve(res.status);
    } catch (err) {
      console.log('Unhandled server error', err);
      return Promise.reject({ _error: 'Token creation error' });
    }
  };
}

export function confirmVerification(
  tokenId: string
): ThunkAction<string | any> {
  return async (store: ThreekitStore) => {
    try {
      const res: any = await store.callApi({
        url: `${usersApiRoot}/users/verify/${tokenId}`,
        method: 'GET',
      });

      // what is appropriate to do here? We confirm verification, then what?
      return Promise.resolve(res.status);
    } catch (err) {
      console.log(err);
      if (err.status === 404) {
        const message =
          err.body && err.body.message ? err.body.message : 'Invalid Token';
        return Promise.reject({ _error: message });
      }
      if (err.status === 422) {
        const message =
          err.body && err.body.message ? err.body.message : 'Invalid Token';
        return Promise.reject({ _error: message });
      }
      console.log('Unhandled server error', err);
      return Promise.reject({ _error: 'Invalid Token' });
    }
  };
}

export function requestPasswordReset(attrs: any): ThunkAction<string | any> {
  return async (store: ThreekitStore) => {
    try {
      const res: any = await store.callApi({
        url: `${usersApiRoot}/users/reset`,
        body: attrs,
        method: 'POST',
      });

      return Promise.resolve(res);
    } catch (err) {
      if (err.status === 500)
        return Promise.reject({ _error: 'Error resetting password' });
    }
  };
}

export function passwordReset(attrs: any, tokenId: string) {
  return async (store: ThreekitStore) => {
    try {
      const res: any = await store.callApi({
        url: `${usersApiRoot}/users/reset/${tokenId}`,
        body: attrs,
        method: 'POST',
      });

      // what is appropriate to do here?
      return Promise.resolve(res);
    } catch (err) {
      if (err.status === 400)
        return Promise.reject({ _error: 'Invalid Password' });
      if (err.message === 'bad token')
        return Promise.reject({ _error: 'Bad Token given' });
      console.log('Unhandled server error', err);
      return Promise.reject({ _error: 'Reset password error' });
    }
  };
}

export function getOrFetchUser(idOrUsername: string) {
  return async (store: ThreekitStore) => {
    const u = await getUser(store, idOrUsername);

    if (!u || u.orgs.length === 0) {
      // ensure we have the orgs when getting a specific user (only returned when fetching a single user)
      return store.dispatch(fetchUser(idOrUsername));
    }
    return u;
  };
}

export function fetchUser(idOrUsername: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${usersApiRoot}/users/${idOrUsername}`,
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setUsers([res]));
    return Promise.resolve(res);
  };
}

export const fetchUsersById = (ids: string[]) => async (
  store: ThreekitStore
) => {
  if (!ids || !ids.length) {
    return [];
  }

  // Ensure unique ids
  ids = _.uniq(ids);

  const promises: Array<Promise<any>> = [];

  for (const id of ids) {
    if (!id) {
      continue;
    }
    promises.push(store.dispatch(fetchUser(id)));
  }

  return reflectPromises<User>(promises);
};

interface FetchUsersOptions {
  nameLike?: string;
  cacheTime?: string;
  skipCache?: boolean;
  skipStoreUsers?: boolean;
  orgId?: string;
}

interface UserQuery {
  username?: string;
  ids?: string[];
}

export function fetchUsersForSuper(
  query: PaginationQuery & UserQuery,
  setInState: boolean = true
): ThunkAction<ResultsWithPageData<List<User>>> {
  const options: FetchOptions = {
    apiRoot: `${usersApiRoot}/users`,
    key: 'users',
    query,
    useOrgId: false,
    clazz: User,
  };

  if (setInState) {
    options.setFn = setUsers;
    options.statePath = ['user', 'users'];
  }

  return (store: ThreekitStore) => fetchResources<List<User>>(store, options);
}

export function fetchUsers(opts: FetchUsersOptions = {}) {
  const { nameLike, cacheTime, skipCache, skipStoreUsers } = opts;
  const q = nameLike ? '?' + queryString.stringify({ nameLike }) : '';

  const base = opts.orgId ? `/orgs/${opts.orgId}` : '';
  const url = `${usersApiRoot}${base}/users${q}`;

  return async (store: ThreekitStore) => {
    const res = skipCache
      ? await store.callApi({ url })
      : await store.dispatch(cacheFetch(cacheTime || '2h', url, {}));
    if (res === true) return Promise.resolve(true);
    if (res.error) return Promise.reject(res.error);
    if (!skipStoreUsers) store.dispatch(setUsers(res.users));
    return Promise.resolve(res.users);
  };
}

export function saveProfilePhoto(file: File): ThunkAction<string | any> {
  return async (store: ThreekitStore) => {
    const user = getUser(store);
    const data = new FormData();
    data.append(`files`, file);
    data.append('orgId', user.id);
    const res = await store.callApi({
      url: `${filesApiRoot}/files`,
      body: data,
      method: 'POST',
    });
    return res.files[0].hash;
  };
}

interface UsersQuery {
  orgId?: string;
}

export function getUsers(
  store: ThreekitStore,
  query: UsersQuery = {}
): UsersMap {
  let users = store.getIn(['user', 'users']);
  if (query.orgId) {
    users = users.filter(equalsFilter('orgId', query.orgId));
  }

  return users;
}

function equalsFilter(key: string, v: any) {
  return (item: any) => item[key] === v;
}

const publicApi = {
  reducer,
  actions: { getOrFetchUser },
  selectors: { getUser, getEditingUser },
  records: [User, UserState],
};
export default publicApi;
