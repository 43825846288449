// Simple, persistent api cache for now.
let apiCache = {};
const fetchingCache = {};

exports.clearCache = function clearCache() {
  apiCache = {};
};

const NUM_CONCURRENT = 15;
let numRequests = 0;
let numRequesting = 0;
const requestQueue = [];

function makeRequest(fn) {
  numRequests++;
  //console.log('makeRequest', numRequesting, requestQueue.length, numRequests);
  if (numRequesting >= NUM_CONCURRENT) {
    requestQueue.push(fn);
  } else {
    numRequesting += 1;
    fn();
  }
}

function doneRequest() {
  //console.log('doneRequest', numRequesting, requestQueue.length);
  numRequesting -= 1;
  if (requestQueue.length && numRequesting < NUM_CONCURRENT) {
    numRequesting += 1;
    requestQueue.shift()();
  }
}

module.exports = function apiBase(fetchNext) {
  return (
    store,
    {
      types, // action types: [request,success,failure]
      url, // url, or array of urls to try
      fireAndForget, // Return immediately, with either result or null
      successPayload, // If not in fireAndForget mode, use the payload, not the response as the success action payload
      payload, // The payload to be used by default for the success action
      queryKey, // A unique key used to identify the request
      ignoreCache, // Don't cache the result
      /**
       * This function is passed the abort callback on the active request, so that it can
       * be bound outside of this function call.
       *
       * TODO?: this api was added to preserve backwards compatibility, but a preferable
       * solution would involve changing the return type.
       */
      bindToRequestAbort,
      method, //  request method
      contentType, // content type identifier, not mime (ie 'json','img','binary')
      body, // request body
      files,
      success, // success callback
      failure, // failure callback
      decompress, // lzma decompress the result
      onReadyStateChange, // xhr readystatechange hook
      withCredentials, // pass credentials to xhr request
      ignoreErrorMessage,
      responseURL, // callback to receive the responseURL from the request (ie, to get the resolved hash out of the url)
    }
  ) => {
    let urls = Array.isArray(url) ? url : [url];
    if (!contentType) contentType = 'json';
    const [requestType, successType, failureType] = types || [
      'IGN',
      'IGN',
      'IGN',
    ];

    // If we provide a 'queryKey', there can be only one query at once
    if (queryKey) {
      if (apiCache[queryKey]) {
        return fireAndForget
          ? apiCache[queryKey]
          : Promise.resolve(apiCache[queryKey]);
      }

      if (fetchingCache[queryKey]) {
        if (fireAndForget) {
          fetchingCache[queryKey].push({ payload, store });
          store.dispatch({ type: requestType, payload });
          return;
        } else {
          return new Promise((resolve, reject) => {
            fetchingCache[queryKey].push({ payload, store, resolve, reject });
            store.dispatch({ type: requestType, payload });
          });
        }
      }

      fetchingCache[queryKey] = [{ payload, store }];
    }

    store.dispatch({ type: requestType, payload });

    var promise = new Promise((resolve, reject) => {
      const meta = {
        queryKey,
        payload,
        successType,
        success,
        failureType,
        failure,
      };

      function onFailure(error, workerMeta) {
        doneRequest();

        const { failureType, failure, queryKey, payload } = workerMeta || meta;

        if (queryKey && fetchingCache[queryKey]) {
          fetchingCache[queryKey].forEach(({ payload, store, reject }) => {
            store.dispatch({
              type: failureType,
              payload,
              error,
              ignoreErrorMessage,
            });
            if (reject) reject(error);
          });
          delete fetchingCache[queryKey];
        } else {
          store.dispatch({
            type: failureType,
            payload: payload,
            error,
            ignoreErrorMessage,
          });
        }

        if (failure) failure(error);
        reject(error);
      }

      function onSuccessEnd(buffer, workerMeta) {
        const { successType, sucess, queryKey, payload } = workerMeta || meta;
        if (queryKey && fetchingCache[queryKey]) {
          if (!ignoreCache) apiCache[queryKey] = buffer;
          fetchingCache[queryKey].forEach(({ payload, store, resolve }) => {
            store.dispatch({
              type: successType,
              payload: fireAndForget || successPayload ? payload : buffer,
              response: buffer,
            });
            if (resolve) resolve(buffer);
          });
          delete fetchingCache[queryKey];
        } else {
          store.dispatch({
            type: successType,
            payload: fireAndForget || successPayload ? payload : buffer,
            response: buffer,
          });
        }

        if (success) success(buffer);
        resolve(buffer);
      }

      function onSuccess(response) {
        doneRequest();

        if (decompress) {
          lzmaDecompressor(response, meta, onSuccessEnd, onFailure);
        } else {
          onSuccessEnd(response);
        }
      }

      function onAbort() {
        reject('request aborted');
      }

      makeRequest(() =>
        fetchNext(
          urls[0],
          urls.slice(1),
          onSuccess,
          onFailure,
          bindToRequestAbort,
          onAbort,
          {
            contentType,
            method,
            body,
            files,
            onReadyStateChange,
            withCredentials,
            responseURL,
          }
        )
      );
    });

    if (fireAndForget)
      promise.catch(function(err) {
        // If `fireAndForget`, we need to catch any errors so we don't hit the unhandled exception handler and exit the process.
        // Log the error here and continue, since fireAndForget queries may continue in the face of errors.
        console.error(err);
      });

    return fireAndForget ? null : promise;
  };
};
