import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { List, Record, Map } from 'immutable';
import _ from 'lodash';
import {
  fetchResources,
  PaginationQuery,
  ResultsWithPageData,
} from 'sections/app/pagination';
import { getUser } from 'sections/auth/user';
import { appsApiRoot } from '../../conf';

export interface AppProps {
  id: string;
  name: string;
  orgId: string;
  url: string;
  token?: string;
  createdBy: string;
  configuration: any;
  metadata: any;
  createdAt: Date;
}

const defaultAppProps = {
  id: '',
  name: '',
  url: '',
  createdBy: '',
  orgId: '',
  token: '',
  configuration: {},
  metadata: {},
  createdAt: new Date(),
};

export class App extends Record(defaultAppProps) implements AppProps {}

interface AppStateProps {
  apps: Map<string, App>;
}

type AppStateType = Record<AppStateProps>;

const defaultAppStateProps: AppStateProps = {
  apps: Map(),
};

export class AppState extends Record(defaultAppStateProps)
  implements AppStateProps {}

const initialState = new AppState();

const enum Actions {
  SET_APP = 'SET_APP',
  SET_APPS = 'SET_APPS',
  DELETE_APP = 'ADD_APP',
  REMOVE_APP = 'REMOVE_APP',
}

const reducer = {
  initialState,
  [Actions.SET_APP](state: AppStateType, payload: AppProps) {
    return state.setIn(['apps', payload.id], payload);
  },

  [Actions.SET_APPS](state: AppStateType, apps: AppProps[]) {
    const newApps = apps.reduce((acc: Map<string, App>, app: AppProps) => {
      return acc.set(app.id, new App(app));
    }, state.get('apps'));
    return state.set('apps', newApps);
  },
  [Actions.REMOVE_APP](state: AppStateType, id: string) {
    return state.deleteIn(['apps', id]);
  },
};

export function fetchApps(
  query?: PaginationQuery & { orgId?: string }
): ThunkAction<ResultsWithPageData<List<App>>> {
  return async (store: ThreekitStore) =>
    await fetchResources<List<App>>(store, {
      apiRoot: `${appsApiRoot}/apps`,
      key: 'apps',
      clazz: App,
      setFn: setApps,
      statePath: ['apps', 'apps'],
      query,
    });
}

export function fetchApp(id: string, options: { onlineSession: boolean }) {
  return (store: ThreekitStore) => {
    let url = `${appsApiRoot}/apps/${id}`;
    if (options.onlineSession) url += `?onlineSession=true`;
    return store.callApi({ url });
  };
}

export function createApp(attrs: any) {
  return async (store: ThreekitStore) => {
    const user = getUser(store);
    attrs.createdBy = user.get('id');
    const res = await store.callApi({
      url: `${appsApiRoot}/apps`,
      body: attrs,
      method: 'POST',
    });
    store.dispatch(addApp(res));
    return res;
  };
}

export function updateApp(attrs: any) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${appsApiRoot}/apps/${attrs.id}`,
      body: attrs,
      method: 'PUT',
    });
    store.dispatch(addApp(res));
    return res;
  };
}

export function deleteApp(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${appsApiRoot}/apps/${id}`,
      method: 'DELETE',
    });
    store.dispatch(removeApp(id));
    return res;
  };
}

export function getApps(store: ThreekitStore) {
  const apps = store.getIn(['apps', 'apps']);
  return Object.values(apps.toJS());
}

function setApps(apps: AppProps[]) {
  return { type: Actions.SET_APPS, payload: apps };
}

function addApp(app: AppProps) {
  return { type: Actions.SET_APP, payload: app };
}

function removeApp(id: string) {
  return { type: Actions.REMOVE_APP, payload: id };
}

const publicApi = {
  reducer,
  actions: {},
  selectors: {},
};

export default publicApi;
