import { casModule, configuratorsModule } from '@threekit/cas';
import { reducer } from '@threekit/redux-form/immutable';
import cache from 'sections/app/cache';
import ui from 'sections/app/ui';
import assets from 'sections/assets/assets';
import user from 'sections/auth/user';
import comments from 'sections/comments/comments';
import configurations from 'sections/configurations/configurations';
import editor from 'sections/editor/editor';
import jobs from 'sections/jobs/jobs';
import notifications from 'sections/notifications/notifications';
import orgs from 'sections/orgs/orgs';
import renders from 'sections/renders/renders';
import apps from 'sections/apps/apps';
import customers from 'sections/customers/customers';
import orders from 'sections/orders/orders';

export default {
  apps,
  assets,
  cache,
  cas: casModule,
  comments,
  configurators: configuratorsModule,
  editor,
  form: { reducer, actions: {}, selectors: {} },
  jobs,
  notifications,
  orgs,
  renders,
  user,
  ui,
  configurations,
  customers,
  orders,
};
