const apiBase = require('./apiBase');
// import * as opentype from 'opentype.js';

function useAuth(authOpts) {
  const authToken = authOpts && authOpts.authToken;
  const cache = authOpts && authOpts.cache;

  function fetchNext(
    url,
    urls,
    success,
    failure,
    bindToRequestAbort,
    onAbort,
    options
  ) {
    // console.log('fetchNext', method, url, withCredentials);
    const {
      contentType,
      method,
      files,
      onReadyStateChange,
      withCredentials,
      responseURL,
    } = options;
    let body = options.body;
    function tryNext(err) {
      if (urls.length) {
        return fetchNext(
          urls[0],
          urls.slice(1),
          success,
          failure,
          bindToRequestAbort,
          onAbort,
          options
        );
      } else {
        failure(err);
      }
    }

    if (contentType === 'img') {
      let img = new Image();
      img.crossOrigin = 'anonymous'; // don't send cookies, but do send Origin: header
      img.addEventListener('load', e => {
        success(img);
      });
      img.addEventListener('error', tryNext);
      img.src = url; //s.shift();
    } else if (contentType === 'font') {
      // opentype.load(url, (err, font) => {
      //   if (err) return failure(err);
      //   success(font);
      // });
      /*  } else if (contentType === 'external') {
    // This will need to use JSONP in some fashion (either dynamic function,
    // or some sort of global plugin namespace in order to work, since
    // CORS prevents us from just loading the file via xhr
    throw new Error('External Script support not allowed in the browser'); */
    } else {
      let request = new XMLHttpRequest();
      const requestMethod = method || 'GET';
      if (withCredentials !== false) request.withCredentials = true; //withCredentials || requestMethod !== 'GET';

      if (cache && cache.maxAge && cache.scope && /get/i.test(requestMethod)) {
        const queryChar = url.indexOf('?') === -1 ? '?' : '&';
        url += `${queryChar}cacheMaxAge=${cache.maxAge}&cacheScope=${
          cache.scope
        }`;
      }
      if (authToken) {
        const queryChar = url.indexOf('?') === -1 ? '?' : '&';
        const u = url + queryChar + 'bearer_token=' + authToken;
        request.open(requestMethod, u, true);
      } else {
        request.open(requestMethod, url, true);
      }

      if (body && !files && !(body instanceof FormData)) {
        request.setRequestHeader('Content-Type', 'application/json');
        if (typeof body !== 'string') body = JSON.stringify(body);
      }
      if (files) {
        body = new FormData();
        for (const fileData of files) {
          body.append(fileData.name, fileData.file);
        }
      }

      if (contentType === 'binary') request.responseType = 'arraybuffer';
      if (onReadyStateChange) {
        request.onreadystatechange = function() {
          onReadyStateChange(request);
        };
      }
      request.onload = xhr => {
        let response = request.response;
        // console.log('xhr.onload', request.status);

        if (
          (request.status >= 200 && request.status < 400) ||
          (request.status === 0 && request.response) // file: url
        ) {
          if (contentType === 'json') response = JSON.parse(response);
          success(response);
          if (responseURL && request.responseURL)
            responseURL(request.responseURL);
        } else {
          try {
            tryNext(JSON.parse(response));
          } catch (err) {
            // console.log('no json from server?', err, request);
            tryNext({
              status: request.status || 500,
              message: 'Error',
              code: 'unknown_error',
            });
          }
        }
      };

      request.onerror = function(errArg) {
        // console.log('onError handler?', errArg, request.status);
        tryNext({
          status: request.status || 503,
          message: 'Error',
          code: 'unknown_error',
        });
      };

      request.send(body);

      request.onabort = onAbort;

      bindToRequestAbort && bindToRequestAbort(request.abort.bind(request));
    }
  }
  return fetchNext;
}

const callApi = apiBase(useAuth({}));

export function browserFetchWithAuth(opts) {
  return apiBase(useAuth(opts));
}

export default callApi;
