import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { List, Map, Record } from 'immutable';
import _ from 'lodash';
import { cacheFetch } from 'sections/app/cache';
import {
  FetchOptions,
  fetchResources,
  PaginationQuery,
  reflectPromises,
  ResultsWithPageData,
} from 'sections/app/pagination';
import { fetchUsersById, getUser, User } from 'sections/auth/user';
import { Limit } from 'sections/renders/containers/LimitConfiguration';
import { Subset } from 'sections/renders/renders';
import { jobsApiRoot } from '../../conf';

export interface JobParameters {
  subset?: {
    attributes: Subset;
    layers: Limit;
  };
}

interface JobProps {
  id: string;
  orgId: string;
  title: string;
  type: string;
  status: string;
  createdBy: string;
  tasks: Task[];
  createdAt: Date;
  updatedAt: Date;
  taskStatusPending: number;
  taskStatusRunning: number;
  taskResultFailures: number;
  taskResultSuccesses: number;
  taskStatusStopped: number;
  taskCount: number;
  taskProgress: number;
  user: User;
  timeElapsed: number;
  timeRemaining: number;
  schedulerState: string;
  parameters: JobParameters;
}

const defaultJobProps = {
  id: '',
  title: '',
  type: '',
  status: '',
  createdBy: '',
  orgId: '',
  tasks: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  taskStatusPending: 0,
  taskStatusRunning: 0,
  taskStatusStopped: 0,
  taskResultFailures: 0,
  taskResultSuccesses: 0,
  taskCount: 0,
  taskProgress: 0,
  user: new User(),
  timeElapsed: 0,
  timeRemaining: -1,
  schedulerState: '',
  parameters: {},
};

interface TaskProps {
  id: string;
  projectId: string;
  orgId: string;
  jobId: string;
  title: string;
  type: string;
  createdBy: string;
  runs: any;
  runProgress: number;
  runStatus: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  parameters: object;
}

const defaultTaskProps = {
  id: '',
  title: '',
  type: '',
  createdBy: '',
  projectId: '',
  jobId: '',
  orgId: '',
  runProgress: 0,
  runStatus: '',
  status: '',
  runs: [],
  parameters: {},
  createdAt: new Date(),
  updatedAt: new Date(),
};

export type JobType = Record<JobProps>;

export class Job extends Record(defaultJobProps) implements JobProps {}

export class Task extends Record(defaultTaskProps) implements TaskProps {}

interface JobStateProps {
  jobs: Map<string, Job>;
  tasks: Map<string, Task>;
  runs: Map<string, any>;
}

type JobStateType = Record<JobStateProps>;

const defaultJobStateProps: JobStateProps = {
  jobs: Map(),
  tasks: Map(),
  runs: Map(),
};

export class JobState extends Record(defaultJobStateProps)
  implements JobStateProps {}

const initialState = new JobState();

const enum Actions {
  SET_JOB = 'SET_JOB',
  SET_JOBS = 'SET_JOBS',
  ADD_JOB_TASK = 'ADD_JOB_TASK',
  SET_JOB_TASKS = 'SET_JOB_TASKS',
  SET_RUNS = 'SET_RUNS',
}

interface JobStateProps {
  jobs: Map<string, Job>;
  tasks: Map<string, Task>;
  runs: Map<string, any>;
}

function translateJobStatus(props: JobProps) {
  let status = 'Complete';
  if (props.taskStatusPending > 0 || props.taskStatusRunning > 0) {
    status = 'Ongoing';
  } else if (props.taskResultFailures > 0) {
    status = 'Failed';
  }
  if (props.schedulerState === 'paused') {
    status = 'Paused';
  }
  if (props.schedulerState === 'cancelled') {
    status = 'Cancelled';
  }
  return new Job(Object.assign({}, props, { status }));
}

function inflateJob(store: ThreekitStore, job: Job) {
  const user = getUser(store, job.createdBy);
  return job.set('user', user);
}

const reducer = {
  initialState,
  [Actions.SET_JOB](state: JobStateType, payload: JobProps) {
    return state.setIn(['jobs', payload.id], translateJobStatus(payload));
  },

  [Actions.SET_JOBS](state: JobStateType, jobs: JobProps[]) {
    const newJobs = jobs.reduce((acc: Map<string, Job>, job: JobProps) => {
      return acc.set(job.id, translateJobStatus(job));
    }, state.get('jobs'));
    return state.set('jobs', newJobs);
  },

  [Actions.ADD_JOB_TASK](state: JobStateType, task: TaskProps) {
    return state.setIn(['tasks', task.id], new Task(task));
  },

  [Actions.SET_JOB_TASKS](state: JobStateType, tasks: TaskProps[]) {
    const newTasks = tasks.reduce((acc: Map<string, Task>, task: TaskProps) => {
      return acc.set(task.id, new Task(task));
    }, state.get('tasks'));
    return state.set('tasks', newTasks);
  },
  [Actions.SET_RUNS](
    state: JobStateType,
    payload: { runs: any; orgId: string }
  ) {
    return state.setIn(['runs', payload.orgId], payload.runs);
  },
};

export function fetchJobs(
  query?: PaginationQuery & JobQuery
): ThunkAction<ResultsWithPageData<List<Job>>> {
  const options: FetchOptions = {
    apiRoot: `${jobsApiRoot}/jobs`,
    key: 'jobs',
    clazz: Job,
    setFn: setJobs,
    statePath: ['jobs', 'jobs'],
    query,
  };

  return async (store: ThreekitStore) => {
    const jobs = await fetchResources<List<Job>>(store, options);

    // Get list of users that need to be fetched
    const { userIds } = jobs.results.reduce(
      (acc, group) => {
        acc.userIds.push(group.createdBy);
        return acc;
      },
      { userIds: [] as string[] }
    );

    await Promise.all([store.dispatch(fetchUsersById(userIds))]);

    jobs.results = jobs.results.map((job, key) => inflateJob(store, job));

    return jobs;
  };
}

export function fetchJob(id: string) {
  return async (store: ThreekitStore) => {
    const job = getJob(store, id);
    if (job) return Promise.resolve(job);
    const res = await store.callApi({
      url: `${jobsApiRoot}/jobs/${id}`,
    });

    if (res.error) return Promise.reject(res.error);
    store.dispatch(addJob(res));
    return Promise.resolve(getJob(store, id));
  };
}

export const fetchJobsById = (ids: string[]) => async (
  store: ThreekitStore
) => {
  if (!ids || !ids.length) {
    return [];
  }

  // Ensure unique ids
  ids = _.uniq(ids);

  const promises: Array<Promise<any>> = [];

  for (const id of ids) {
    if (!id) {
      continue;
    }
    promises.push(store.dispatch(fetchJob(id)));
  }

  return reflectPromises<Job>(promises);
};

export function fetchTasks(
  query?: PaginationQuery & TaskQuery
): ThunkAction<ResultsWithPageData<List<Task>>> {
  return async (store: ThreekitStore) =>
    await fetchResources<List<Task>>(store, {
      apiRoot: `${jobsApiRoot}/jobs/tasks`,
      key: 'tasks',
      clazz: Task,
      setFn: setTasks,
      statePath: ['jobs', 'tasks'],
      query,
    });
}

export function fetchTask(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.dispatch(
      cacheFetch('2h', `${jobsApiRoot}/jobs/tasks/${id}`, {})
    );
    if (res.error) return Promise.reject(res.error);
    store.dispatch(addTask(res));
    return Promise.resolve(getTask(store, id));
  };
}

export function fetchJobsForReports(orgId: string) {
  return async (store: ThreekitStore) => {
    const res = await store.dispatch(
      cacheFetch('2h', `${jobsApiRoot}/jobs/reports?orgId=${orgId}`, {
        orgId,
      })
    );
    if (res.error) return Promise.reject(res.error);
    // FIX ME: EVERY USER HAS ORG ADMIN ACCESS ?
    store.dispatch(setRuns(res.runs, orgId));
    return Promise.resolve(res.runs);
  };
}

export function createJob(attrs: any) {
  return async (store: ThreekitStore) => {
    const user = getUser(store);
    attrs.createdBy = user.get('id');
    const res = await store.callApi({
      url: `${jobsApiRoot}/jobs/jobs`,
      body: attrs,
      method: 'POST',
    });
    const job = res && res.job;
    store.dispatch(addJob(job));
    return Promise.resolve(job);
  };
}

export function pauseJob(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${jobsApiRoot}/jobs/${id}/pause`,
      method: 'POST',
    });
    const job = res && res.job;
    store.dispatch(addJob(job));
    return Promise.resolve(job);
  };
}

export function resumeJob(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${jobsApiRoot}/jobs/${id}/resume`,
      method: 'POST',
    });
    const job = res && res.job;
    store.dispatch(addJob(job));
    return Promise.resolve(job);
  };
}

export function cancelJob(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${jobsApiRoot}/jobs/${id}/cancel`,
      method: 'POST',
    });
    const job = res && res.job;
    store.dispatch(addJob(job));
    return Promise.resolve(job);
  };
}

export function retryJob(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${jobsApiRoot}/jobs/${id}/retry`,
      method: 'POST',
    });
    const job = res && res.job;
    store.dispatch(addJob(job));
    return Promise.resolve(job);
  };
}

export interface JobQuery {
  orgId?: string;
  status?: string;
}
export interface TaskQuery {
  jobId?: string;
  orgId?: string;
}

export function getJob(store: ThreekitStore, id: string): Job {
  return store.getIn(['jobs', 'jobs', id]);
}

export function getJobs(store: ThreekitStore, query: JobQuery = {}): List<Job> {
  const { orgId, status } = query;
  return List(
    store
      .getIn(['jobs', 'jobs'])
      .filter((job: Job) => (orgId && orgId === job.orgId) || true)
      .filter((job: Job) => (status && status === job.status) || true)
      .values()
  );
}

export function getTask(store: ThreekitStore, id: string) {
  return store.getIn(['jobs', 'tasks', id]);
}

export function getTasks(store: ThreekitStore, query: TaskQuery = {}) {
  const { orgId, jobId } = query;
  return List(
    store
      .getIn(['jobs', 'tasks'])
      .filter((task: Task) => (orgId && orgId === task.orgId) || true)
      .filter((task: Task) => (jobId && jobId === task.jobId) || true)
      .values()
  );
}

function setJobs(jobs: JobProps[]) {
  return { type: Actions.SET_JOBS, payload: jobs };
}

function addJob(job: JobProps) {
  return { type: Actions.SET_JOB, payload: job };
}

function setTasks(tasks: TaskProps[]) {
  return { type: Actions.SET_JOB_TASKS, payload: tasks };
}

function addTask(task: TaskProps) {
  return { type: Actions.ADD_JOB_TASK, payload: task };
}

function setRuns(runs: any, orgId: string) {
  return { type: Actions.SET_RUNS, payload: { runs, orgId } };
}

export function getRuns(store: ThreekitStore, orgId: string) {
  return store.getIn(['jobs', 'runs', orgId]);
}

const publicApi = {
  reducer,
  actions: {},
  selectors: {},
};

export default publicApi;
