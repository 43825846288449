import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { assetJobsApiRoot, layersApiRoot } from 'conf';
import { List, Map, Record } from 'immutable';
import _ from 'lodash';
import {
  FetchOptions,
  fetchResources,
  PaginationQuery,
  ResultsWithPageData,
} from 'sections/app/pagination';
import {
  Asset,
  fetchAsset,
  fetchAssetsById,
  getAsset,
} from 'sections/assets/assets';
import { fetchJobsById, getJob, Job } from 'sections/jobs/jobs';
import { Limit } from './containers/LimitConfiguration';

export interface LayerProps {
  id: string;
  assetId: string;
  assetLayer: string;
  assetLayerConfiguration: { [key: string]: any };
  assetVersion: string;
  branch: string;
  fileId: string;
  jobId: string;
  metadata: { [key: string]: any };
  orgId: string;
  stageId: string;
  stageVersion: string;
  stageConfiguration: { [key: string]: any };
  taskId: string;
  createdAt: Date;
}

const defaultLayerProps: LayerProps = {
  id: '',
  assetId: '',
  assetLayer: '',
  assetLayerConfiguration: {},
  assetVersion: '',
  orgId: '',
  stageId: '',
  stageVersion: '',
  stageConfiguration: {},
  jobId: '',
  taskId: '',
  fileId: '',
  branch: '',
  metadata: {},
  createdAt: new Date(),
};

export interface LayerQuery {
  id?: string;
  assetId?: string;
  orgId?: string;
  assetLayer?: string;
  assetLayerConfiguration?: { [key: string]: any };
  stageId?: string;
  stageConfiguration?: { [key: string]: any };
  jobId?: string;
  branch?: string;
  groupBy?: string;
  resultType?: string;
  metadata?: { [key: string]: any };
}

export class Layer extends Record(defaultLayerProps) implements LayerProps {}

export interface GroupProps {
  count: number;
  jobId: string;
  fileId: string;
  assetId: string;
  orgId: string;
  job: Job;
  asset: Asset;
}

const defaultGroupProps: GroupProps = {
  count: 0,
  jobId: '',
  fileId: '',
  assetId: '',
  orgId: '',
  job: new Job(),
  asset: new Asset(),
};

export class Group extends Record(defaultGroupProps) implements GroupProps {}

export interface Subset {
  [attributeName: string]: Limit;
}

export interface CreateRenderProps {
  orgId: string;
  jobName: string;
  stageId: string;
  type: 'webgl' | 'vray';
  subset?: {
    attributes: Subset;
    layers: Limit;
  };
  resolution?: number;
}

function inflateGroup(store: ThreekitStore, group: Group) {
  const job = getJob(store, group.jobId);
  const asset = getAsset(store, group.assetId);

  return group.set('job', job).set('asset', asset);
}

export function createRender(assetId: string, attrs: CreateRenderProps) {
  const { resolution, ...restAttrs } = attrs;

  const settings = { output: {} as any };

  if (resolution)
    settings.output.resolution = {
      width: resolution,
    };

  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${assetJobsApiRoot}/asset-jobs/${assetId}/renders`,
      body: {
        settings,
        ...restAttrs,
      },
      method: 'POST',
    });

    if (res.error) return Promise.reject(res.error);
    return Promise.resolve(res.jobId);
  };
}

export function fetchLayers(
  query: PaginationQuery & LayerQuery = {}
): ThunkAction<ResultsWithPageData<List<Layer>>> {
  const options: FetchOptions = {
    apiRoot: `${layersApiRoot}/layers`,
    key: 'layers',
    query,
    clazz: Layer,
  };

  return async (store: ThreekitStore) => {
    const data = await fetchResources<List<Layer>>(store, options);
    const resolveRefs = data.results.map(async item => {
      const layerConfig: { [key: string]: string } = {};
      for (const i in item.assetLayerConfiguration) {
        if (!item.assetLayerConfiguration[i]) continue;
        if (typeof item.assetLayerConfiguration[i] !== 'string') {
          // Asset reference
          const assetId = item.assetLayerConfiguration[i].assetId;
          if (!assetId) {
            layerConfig[i] = JSON.stringify(item.assetLayerConfiguration[i]);
          } else {
            const asset = await store.dispatch(fetchAsset(assetId));
            layerConfig[i] = asset && asset.name;
          }
        } else {
          layerConfig[i] = item.assetLayerConfiguration[i];
        }
      }
      item = item.set('assetLayerConfiguration', layerConfig);
      return item;
    });
    const results = await Promise.all(resolveRefs);
    data.results = List(results);
    return data;
  };
}

export function fetchGroups(
  query: PaginationQuery & LayerQuery = {},
  groupBy: string = 'jobId'
): ThunkAction<ResultsWithPageData<List<Group>>> {
  const options: FetchOptions = {
    apiRoot: `${layersApiRoot}/layers/groups/${groupBy}`,
    key: 'groups',
    clazz: Group,
    query,
  };

  return async (store: ThreekitStore) => {
    const groups = await fetchResources<List<Group>>(store, options);

    // Get list of assets and jobs that need to be fetched
    const { jobIds, assetIds } = groups.results.reduce(
      (acc, group) => {
        acc.assetIds.push(group.assetId);
        acc.jobIds.push(group.jobId);
        return acc;
      },
      { jobIds: [] as string[], assetIds: [] as string[] }
    );

    await Promise.all([
      store.dispatch(fetchAssetsById(assetIds)),
      store.dispatch(fetchJobsById(jobIds)),
    ]);

    groups.results = groups.results.map((group, key) =>
      inflateGroup(store, group)
    );

    return groups;
  };
}

const publicApi = {};

export default publicApi;
