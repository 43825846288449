import { ThreekitStore } from '@threekit/redux-store';
import { List, Record } from 'immutable';

export type Mode = 'edit' | 'logic';

export interface EditorStateProps {
  selectedConfiguratorId: string | null;
  collapsed: List<boolean>;
  assetsPanelCollapsed: boolean;
  statisticsVisible: boolean;
  manualSave: boolean;
}

const defaultEditorStateProps: EditorStateProps = {
  selectedConfiguratorId: null,
  collapsed: List([false, false]),
  assetsPanelCollapsed: false,
  statisticsVisible: false,
  manualSave: false,
};

type EditorStateType = Record<EditorStateProps>;

export class EditorState extends Record(defaultEditorStateProps)
  implements EditorStateProps {}

const initialState = new EditorState();

const enum Actions {
  SET_SELECTED_CONFIGURATOR = 'SET_SELECTED_CONFIGURATOR',
  COLLAPSE = 'COLLAPSE',
  TOGGLE_ASSETS_PANEL = 'TOGGLE_ASSETS_PANEL',
  TOGGLE_MANUAL_SAVE = 'TOGGLE_MANUAL_SAVE',
  SET_STATISTICS_VISIBLE = 'SET_STATISTICS_VISIBLE',
}

const reducer = {
  initialState,

  [Actions.SET_SELECTED_CONFIGURATOR](
    state: EditorStateType,
    id: string | null
  ) {
    return state.set('selectedConfiguratorId', id);
  },

  [Actions.COLLAPSE](
    state: EditorStateType,
    { left, collapsed }: { left: boolean; collapsed: boolean }
  ) {
    return state.setIn(['collapsed', left ? 0 : 1], collapsed);
  },

  [Actions.TOGGLE_ASSETS_PANEL](state: EditorStateType) {
    const collapsed = state.getIn(['assetsPanelCollapsed']);
    return state.set('assetsPanelCollapsed', !collapsed);
  },

  [Actions.SET_STATISTICS_VISIBLE](state: EditorStateType, visible: boolean) {
    return state.set('statisticsVisible', visible);
  },

  [Actions.TOGGLE_MANUAL_SAVE](state: EditorStateType, manualSave: boolean) {
    return state.set('manualSave', manualSave);
  },
};

export function isCollapsed(store: ThreekitStore, left: boolean): boolean {
  return store.getIn(['editor', 'collapsed', left ? 0 : 1]);
}

export function getCollapsed(store: ThreekitStore): List<boolean> {
  return store.getIn(['editor', 'collapsed']);
}

export function isManualSave(store: ThreekitStore): boolean {
  return store.getIn(['editor', 'manualSave']);
}

export function isAssetsCollapsed(store: ThreekitStore): boolean {
  return store.getIn(['editor', 'assetsPanelCollapsed']);
}

export function getSelectedConfigurator(store: ThreekitStore) {
  return store.getIn(['editor', 'selectedConfiguratorId']);
}

export function isStatisticsVisible(store: ThreekitStore) {
  return store.getIn(['editor', 'statisticsVisible']);
}

export function setSelectedConfigurator(id: string | null) {
  return { type: Actions.SET_SELECTED_CONFIGURATOR, payload: id };
}

export function collapse(left: boolean, collapsed: boolean) {
  return { type: Actions.COLLAPSE, payload: { left, collapsed } };
}

export function toggleAssetsPanel() {
  return { type: Actions.TOGGLE_ASSETS_PANEL };
}

export function setStatisticsVisible(visible: boolean) {
  return { type: Actions.SET_STATISTICS_VISIBLE, payload: visible };
}

export function setManualSave(manualSave: boolean) {
  return { type: Actions.TOGGLE_MANUAL_SAVE, payload: manualSave };
}

const publicApi = {
  reducer,
  actions: {},
  selectors: {},
  records: [EditorState],
  persist: (state: EditorState) => state,
};

export default publicApi;
