import Rollbar from 'rollbar';

let RollbarInstance: Rollbar;
export function initRollbar(conf: Rollbar.Configuration) {
  RollbarInstance = new Rollbar(conf);
}

export function rollbar() {
  return (
    RollbarInstance || {
      error: (err: any) => {
        console.error(err);
      },
      info: () => {
        console.log(...arguments);
      },
    }
  );
}
