import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { configurationsApiRoot, interstitialApiRoot } from 'conf';
import { List, Map, Record } from 'immutable';
import _ from 'lodash';
import {
  fetchResources,
  PaginationQuery,
  ResultsWithPageData,
} from 'sections/app/pagination';
import { getOrFetchAsset } from 'sections/assets/assets';

export interface ConfigHtmlPageProps {
  configurationId: string;
  htmlString: string;
}

export interface ConfigurationProps {
  id: string;
  productId: string;
  productVersion: string;
  variant: JSON;
  metadata: JSON;
  orgId: string;
  shortId: string;
  createdAt: Date;
  updatedAt: Date;
}

const defaultConfigurationProps: ConfigurationProps = {
  id: '',
  productId: '',
  productVersion: '',
  variant: JSON.parse('{}'),
  metadata: JSON.parse('{}'),
  orgId: '',
  shortId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};
const defaultConfigHtmlPageProps: ConfigHtmlPageProps = {
  configurationId: '',
  htmlString: '',
};

type ConfigurationType = Record<ConfigurationProps>;

export class Configuration extends Record(defaultConfigurationProps)
  implements ConfigurationProps {}
export class ConfigHtmlPage extends Record(defaultConfigHtmlPageProps)
  implements ConfigHtmlPageProps {}

export type ConfigurationsMap = Map<string, Configuration>;
export type ConfigHtmlPageMap = Map<string, ConfigHtmlPage>;

interface ConfigurationStateProps {
  configurations: ConfigurationsMap;
  configHtmlPages: ConfigHtmlPageMap;
}

interface ConfigurationQuery {}

type ConfigurationStateType = Record<ConfigurationStateProps>;

const defaultConfigurationStateProps: ConfigurationStateProps = {
  configurations: Map(),
  configHtmlPages: Map(),
};

export class ConfigurationState extends Record(defaultConfigurationStateProps)
  implements ConfigurationStateProps {}

const initialState = new ConfigurationState();

const enum Actions {
  ADD_CONFIGURATION = 'ADD_CONFIGURATION',
  SET_CONFIGURATION = 'SET_CONFIGURATION',
  SET_CONFIGURATIONS = 'SET_CONFIGURATIONS',
  SET_HTML_PAGE = 'SET_HTML_PAGE',
  GET_HTML_PAGE = 'GET_HTML_PAGE',
}

const reducer = {
  initialState,
  [Actions.SET_CONFIGURATIONS](
    state: ConfigurationStateType,
    configurations: ConfigurationProps[]
  ) {
    const map = configurations.reduce(
      (acc: Map<string, Configuration>, props: ConfigurationProps) => {
        return acc.set(props.id, new Configuration(props));
      },
      state.get('configurations')
    );
    return state.set('configurations', map);
  },
  [Actions.SET_CONFIGURATION](
    state: ConfigurationStateType,
    config: ConfigurationProps
  ) {
    return state.setIn(
      ['configurations', config.id],
      new Configuration(config)
    );
  },
  [Actions.ADD_CONFIGURATION](
    state: ConfigurationStateType,
    config: ConfigurationProps
  ) {
    return state.mergeIn(
      ['configurations', config.id],
      new Configuration(config)
    );
  },
  [Actions.SET_HTML_PAGE](
    state: ConfigurationStateType,
    props: ConfigHtmlPageProps
  ) {
    return state.setIn(
      ['configHtmlPages', props.configurationId],
      new ConfigHtmlPage(props)
    );
  },
};

export function setConfigurations(configurations: ConfigurationProps[]) {
  return { type: Actions.SET_CONFIGURATIONS, payload: configurations };
}

export function setConfiguration(config: ConfigurationProps) {
  return { type: Actions.SET_CONFIGURATION, payload: config };
}

export function setConfigHtmlPage(props: ConfigHtmlPageProps) {
  return { type: Actions.SET_HTML_PAGE, payload: props };
}

export function addConfiguration(config: ConfigurationProps) {
  return { type: Actions.ADD_CONFIGURATION, payload: config };
}

export function getConfigurations(store: ThreekitStore): List<Configuration> {
  return List(store.getIn(['configurations', 'configurations']).values());
}

export function getConfigHtmlPage(
  store: ThreekitStore,
  configId: string
): ConfigHtmlPageProps {
  return store.getIn(['configurations', 'configHtmlPages', configId]);
}

export function getConfiguration(
  store: ThreekitStore,
  id: string
): Configuration {
  return store.getIn(['configurations', 'configurations', id]);
}

export function fetchConfigurations(
  query?: PaginationQuery & ConfigurationQuery
): ThunkAction<ResultsWithPageData<List<Configuration>>> {
  return async (store: ThreekitStore) => {
    const configs = await fetchResources<List<Configuration>>(store, {
      apiRoot: `${configurationsApiRoot}/configurations/`,
      key: 'configurations',
      clazz: Configuration,
      setFn: setConfigurations,
      statePath: ['configurations', 'configurations'],
      query,
    });

    // get unique product id's from queried configurations
    const uniqueProductIds = configs.results.reduce(
      (acc: string[], config: any) => {
        if (!acc.includes(config.productId)) acc.push(config.productId);
        return acc;
      },
      [] as string[]
    );

    // fetch associated products
    await Promise.all(
      uniqueProductIds.map((id: string) => store.dispatch(getOrFetchAsset(id)))
    ).catch(err => console.log(err));

    return configs;
  };
}

export function fetchConfiguration(
  configId: string
): ThunkAction<Configuration> {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${configurationsApiRoot}/configurations/${configId}`,
      method: 'GET',
    });
    if (res === true) {
      return Promise.resolve(true);
    }
    if (res.error) {
      return Promise.reject(res.error);
    }
    store.dispatch(setConfiguration(res));

    return Promise.resolve(res);
  };
}

export const fetchConfigurationsById = (ids: string[]) => async (
  store: ThreekitStore
) => {
  if (!ids || !ids.length) {
    return [];
  }

  // Ensure unique ids
  ids = _.uniq(ids);

  const promises: Array<Promise<any>> = [];

  for (const id of ids) {
    if (!id) {
      continue;
    }
    promises.push(store.dispatch(fetchConfiguration(id)));
  }

  // Use a reflect (similar to bluebird) to ensure Promise.all doesn't fail if a call fails
  const reflect = (promise: Promise<any>) => {
    return promise.then(
      data => ({ data, status: 'fulfilled' }),
      err => ({ err, status: 'rejected' })
    );
  };

  return Promise.all(promises.map(reflect)).then(res => {
    const results = res.filter(result => result.status === 'fulfilled');
    return results.reduce(
      (acc, configuration) => {
        if ('data' in configuration) {
          acc.push(configuration.data);
        }
        return acc;
      },
      [] as Configuration[]
    );
  });
};

export interface CreateConfiguration {
  productId: string;
  productVersion: string;
  orgId: string;
  variant: JSON;
}

export function createConfiguration(body: CreateConfiguration) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${configurationsApiRoot}/configurations`,
      body,
      method: 'POST',
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(addConfiguration(res));

    return Promise.resolve(res);
  };
}

export function fetchInterstitialBasic(configId: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${interstitialApiRoot}/interstitial/${configId}/basic?configurationId=true`,
      method: 'GET',
      contentType: 'text',
    });
    if (res === true) {
      return Promise.resolve(true);
    }
    if (res.error) {
      return Promise.reject(res.error);
    }
    store.dispatch(
      setConfigHtmlPage({
        configurationId: configId,
        htmlString: res,
      })
    );

    return Promise.resolve(res);
  };
}
const publicApi = {
  reducer,
  actions: {},
  selectors: {},
  records: [Configuration, ConfigurationState],
};

export default publicApi;
