import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { Map, Record } from 'immutable';
interface UiStateProps {
  showMobileNavigation: boolean;
}

type UiStateType = Record<UiStateProps>;

const defaultUiStateProps: UiStateProps = {
  showMobileNavigation: false,
};

export class UiState extends Record(defaultUiStateProps)
  implements UiStateProps {}

const initialState = new UiState();

const enum Actions {
  TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU',
}

const reducer = {
  initialState,
  // Toggle mobile menu state or set to an explicit value if 'isActive' boolean provided
  [Actions.TOGGLE_MOBILE_MENU](state: UiStateType, isActive?: boolean) {
    return state.set(
      'showMobileNavigation',
      isActive === undefined ? !state.get('showMobileNavigation') : isActive
    );
  },
};

// Action creators
export function toggleMobileMenu(isActive?: boolean) {
  return { type: Actions.TOGGLE_MOBILE_MENU, payload: isActive };
}

export function dismissMobileMenu() {
  return { type: Actions.TOGGLE_MOBILE_MENU, payload: false };
}

// Basic dispatch functions
export function getShowMobileNavigation(store: ThreekitStore) {
  return store.getIn(['ui', 'showMobileNavigation']);
}

// Export
const publicApi = {
  reducer,
  actions: {},
  selectors: {},
  records: [UiState],
};

export default publicApi;
