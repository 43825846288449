import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import {
  accessTokensApiRoot,
  userInvitesApiRoot,
  usersApiRoot,
  webhooksApiRoot,
} from 'conf';
import { List, Map, Record, Set } from 'immutable';
import queryString from 'query-string';
import { cacheFetch } from 'sections/app/cache';
import {
  FetchOptions,
  fetchResources,
  PaginationQuery,
  ResultsWithPageData,
} from 'sections/app/pagination';
import { getOrFetchUser, User } from 'sections/auth/user';

export interface UserInviteProps {
  id: string;
  createdBy: string;
  orgId: string;
  orgName: string;
  invitedUserId: string;
  email: string;
  createdAt: Date;
  usedAt: Date | null;
  deletedAt: Date | null;
}

export interface DefaultStages {
  modelStage: string;
  materialStage: string;
  textureStage: string;
}

export interface OrgPlayerSettings {
  arButton?: boolean;
  fullScreenButton?: boolean;
  logo?: boolean;
  shareButton?: boolean;
  defaultStages?: DefaultStages;
}

export interface OrgProps {
  id: string;
  name: string;
  slug: string;
  features: OrgFeatures;
  playerSettings: OrgPlayerSettings;
  members: any[];
  userId: string;
  createdAt: Date;
  invites: List<string>;
  tokens: List<string>;
  profile: OrgProfile;
}

export interface OrgProfile {
  logo?: string;
  descriptionShort?: string;
  descriptionLong?: string;
  website?: string;
  contactEmail?: string;
  [key: string]: any;
}

export interface OrgFeatures {
  vrayRendering?: boolean;
  publicShare?: boolean;
  productConfiguration?: ProductConfigurations;
  approvalWorkflow?: boolean;
  productsLimit?: number;
  assetsLimit?: number;
  usersLimit?: number;
  [key: string]: any;
}

export enum ProductConfigurations {
  none = 'none',
  simple = 'simple',
  world = 'advanced',
}

export interface TokenProps {
  id: string;
  name: string;
  orgId: string;
  createdBy: string;
  domains: string[];
  permissions: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface WebhookProps {
  id: string;
  orgId?: string;
  createdBy?: string;
  topic: string;
  address: string;
  filter?: any;
  user?: any;
}

export interface Response {
  code?: number;
  headers?: { [key: string]: string };
  body?: { [key: string]: any };
  createdAt?: Date;
}

export interface WebhookRequestProps {
  id: string;
  orgId: string;
  topic: string;
  webhook: string;
  retry: number;
  response: Response[];
  data: Response;
  status: string;
  address: string;
  statusMsg: { code: number; message: string; retryCount?: number; id: string };
  updatedAt: Date;
}

const defaultOrgProps: OrgProps = {
  id: '',
  name: '',
  slug: '',
  members: [],
  features: {},
  playerSettings: {},
  userId: '',
  profile: {},
  createdAt: new Date(),
  invites: List() as List<string>,
  tokens: List() as List<string>,
};

const defaultUserInviteProps: UserInviteProps = {
  id: '',
  createdBy: '',
  orgId: '',
  orgName: '',
  invitedUserId: '',
  email: '',
  createdAt: new Date(),
  usedAt: null,
  deletedAt: null,
};

const defaultTokenProps: TokenProps = {
  id: '',
  name: '',
  orgId: '',
  createdBy: '',
  domains: [],
  permissions: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: new Date(),
};

const defaultWebhookProps: WebhookProps = {
  id: '',
  orgId: '',
  createdBy: '',
  topic: '',
  address: '',
  filter: {},
  user: {},
};

const defaultWebhookRequestProps: WebhookRequestProps = {
  id: '',
  orgId: '',
  topic: '',
  webhook: '',
  retry: 0,
  address: '',
  status: '',
  data: {},
  response: [],
  statusMsg: { code: 200, message: '', retryCount: 0, id: '' },
  updatedAt: new Date(),
};

export class Org extends Record(defaultOrgProps) implements OrgProps {
  public orgName(id: string): string {
    return this.userId === id ? 'Personal' : this.name;
  }
}

export class UserInvite extends Record(defaultUserInviteProps)
  implements UserInviteProps {}

export class Token extends Record(defaultTokenProps) implements TokenProps {}
export class Webhook extends Record(defaultWebhookProps)
  implements WebhookProps {}
export class WebhookRequest extends Record(defaultWebhookRequestProps)
  implements WebhookRequestProps {}

export type OrgsMap = Map<string, Org>;
export type UserInvitesMap = Map<string, UserInvite>;
export type TokensMap = Map<string, Token>;
export type WebhooksMap = Map<string, Webhook>;
export type WebhookRequestMap = Map<string, WebhookRequest>;

interface OrgStateProps {
  activeOrg: string;
  orgs: OrgsMap;
  invites: UserInvitesMap;
  tokens: TokensMap;
  webhooks: WebhooksMap;
  webhookRequests: WebhookRequestMap;
}

type OrgStateType = Record<OrgStateProps>;

const defaultOrgStateProps: OrgStateProps = {
  activeOrg: '',
  orgs: Map(),
  invites: Map(),
  tokens: Map(),
  webhooks: Map(),
  webhookRequests: Map(),
};

export class OrgState extends Record(defaultOrgStateProps)
  implements OrgStateProps {}

const initialState = new OrgState();

function inflateOrgsWithTokens(props: OrgProps) {
  return new Org(props).set('tokens', List(props.tokens));
}

function inflateOrgs(props: OrgProps) {
  return inflateOrgsWithTokens(props).set('invites', List(props.invites));
}

const enum Actions {
  ADD_ORG = 'ADD_ORG',
  SET_ORG = 'SET_ORG',
  SET_ORGS = 'SET_ORGS',
  SET_ACTIVE_ORG = 'SET_ACTIVE_ORG',
  SET_INVITE = 'SET_INVITE',
  SET_INVITES = 'SET_INVITES',
  ADD_INVITE = 'ADD_INVITE',
  SET_TOKEN = 'SET_TOKEN',
  SET_TOKENS = 'SET_TOKENS',
  ADD_TOKEN = 'ADD_TOKEN',
  ADD_WEBHOOK = 'ADD_WEBHOOK',
  SET_WEBHOOKS = 'SET_WEBHOOKS',
  SET_WEBHOOK = 'SET_WEBHOOK',
  DEL_WEBHOOK = 'DEL_WEBHOOK',
  SET_WEBHOOK_REQUESTS = 'SET_WEBHOOK_REQUESTS',
  ADD_WEBHOOK_REQUEST = 'SET_WEBHOOK_REQUEST',
}

const reducer = {
  initialState,
  [Actions.SET_ORGS](state: OrgStateType, orgs: OrgProps[]) {
    // console.log('set user?', state, user);
    const map = orgs.reduce((acc: OrgsMap, props: OrgProps) => {
      return acc.set(props.id, inflateOrgs(props));
    }, state.get('orgs'));
    return state.set('orgs', map);
  },
  [Actions.SET_ORG](state: OrgStateType, props: OrgProps) {
    return state.mergeIn(['orgs', props.id], inflateOrgs(props));
  },
  [Actions.ADD_ORG](state: OrgStateType, props: OrgProps) {
    return state.setIn(['orgs', props.id], inflateOrgs(props));
  },
  [Actions.SET_ACTIVE_ORG](
    state: OrgStateType,
    props: { id: string; user: User }
  ) {
    return state.set('activeOrg', props.id);
  },
  [Actions.SET_INVITES](state: OrgStateType, userInvites: UserInviteProps[]) {
    const map = userInvites.reduce(
      (acc: UserInvitesMap, props: UserInviteProps) => {
        return acc.set(props.id, new UserInvite(props));
      },
      Map()
    );

    return state.set('invites', map);
  },
  [Actions.SET_INVITE](state: OrgStateType, props: UserInviteProps) {
    return state.setIn(['invites', props.id], new UserInvite(props));
  },
  [Actions.ADD_INVITE](state: OrgStateType, props: UserInviteProps) {
    return state.setIn(['invites', props.id], new UserInvite(props));
  },
  [Actions.SET_TOKEN](state: OrgStateType, props: TokenProps) {
    return state.setIn(['tokens', props.id], new Token(props));
  },
  [Actions.SET_TOKENS](state: OrgStateType, tokens: TokenProps[]) {
    const map = tokens.reduce((acc: TokensMap, props: TokenProps) => {
      return acc.set(props.id, new Token(props));
    }, Map());

    return state.set('tokens', map);
  },
  [Actions.ADD_TOKEN](state: OrgStateType, props: TokenProps) {
    return state.mergeIn(['tokens', props.id], new Token(props));
  },
  [Actions.ADD_WEBHOOK](state: OrgStateType, props: WebhookProps) {
    return state.mergeIn(['webhooks', props.id], new Webhook(props));
  },
  [Actions.SET_WEBHOOKS](state: OrgStateType, webhooks: WebhookProps[]) {
    const map = webhooks.reduce((acc: WebhooksMap, props: WebhookProps) => {
      return acc.set(props.id, new Webhook(props));
    }, Map());
    return state.set('webhooks', map);
  },
  [Actions.SET_WEBHOOK](state: OrgStateType, props: WebhookProps) {
    return state.setIn(['webhooks', props.id], new Webhook(props));
  },
  [Actions.DEL_WEBHOOK](state: OrgStateType, id: string) {
    return state.deleteIn(['webhooks', id]);
  },
  [Actions.SET_WEBHOOK_REQUESTS](
    state: OrgStateType,
    webhookrequests: WebhookRequestProps[]
  ) {
    const map = webhookrequests.reduce(
      (acc: WebhookRequestMap, props: WebhookRequestProps) => {
        return acc.set(props.id, new WebhookRequest(props));
      },
      Map()
    );
    return state.set('webhookRequests', map);
  },
};

export function setOrgs(orgs: OrgProps[]) {
  return { type: Actions.SET_ORGS, payload: orgs };
}
export function setOrg(org: OrgProps) {
  return { type: Actions.SET_ORG, payload: org };
}

export function getOrg(store: ThreekitStore, id: string): Org {
  return store.getIn(['orgs', 'orgs', id]);
}
export function getOrgs(store: ThreekitStore): OrgsMap {
  return store.getIn(['orgs', 'orgs']);
}

export function addOrg(org: OrgProps) {
  return { type: Actions.ADD_ORG, payload: org };
}

export function getActiveOrg(store: ThreekitStore) {
  const orgId = store.getIn(['orgs', 'activeOrg']);
  const org = orgId ? getOrg(store, orgId) : undefined;
  return { orgId, slug: org ? org.slug : '', org };
}

export function getOrFetchOrgBySlug(slug: string): ThunkAction<OrgProps> {
  return async (store: ThreekitStore) => {
    const user: User = store.getIn(['user', 'user']);

    const org = store
      .getIn(['orgs', 'orgs'])
      .find((org: Org) => org.slug === slug);

    if (!org) {
      return user.super
        ? Promise.resolve(await store.dispatch(fetchOrg(slug)))
        : Promise.reject({
            status: '403',
            code: 'Forbidden',
            message: 'Forbidden',
          });
    }
    return Promise.resolve(org);
  };
}

export function setActiveOrg(id: string) {
  return async (store: ThreekitStore) => {
    const user: User = store.getIn(['user', 'user']);
    return { type: Actions.SET_ACTIVE_ORG, payload: { id, user } };
  };
}

export function setActiveOrgBySlug(slug: string) {
  return async (store: ThreekitStore) => {
    const user: User = store.getIn(['user', 'user']);
    if (user.isAnon)
      return Promise.reject({ message: 'Must be logged in', status: 401 });

    const org: OrgProps = await store.dispatch(getOrFetchOrgBySlug(slug));
    if (!org)
      return Promise.reject({ message: 'Unknown org: ' + slug, status: 401 });
    await store.dispatch({
      type: Actions.SET_ACTIVE_ORG,
      payload: { id: org.id, user },
    });
    return Promise.resolve(org);
  };
}

export interface CreateOrg {
  name: string;
}

export function createOrg(body: CreateOrg): ThunkAction<string> {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${usersApiRoot}/orgs`,
      body,
      method: 'POST',
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(addOrg(res));
    return Promise.resolve(res.id);
  };
}

export function updateOrg(props: OrgProps): ThunkAction<string> {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${usersApiRoot}/orgs/${props.id}`,
      body: props,
      method: 'PUT',
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setOrg(res));
    return Promise.resolve(res.id);
  };
}

interface FetchOrgsOpts {
  showAll?: boolean;
}

export interface OrgQuery {
  slug?: string;
  name?: string;
  ids?: string[];
  showAll?: boolean;
}
export function fetchOrgsForSuper(
  query: PaginationQuery & OrgQuery,
  setInState: boolean = true
): ThunkAction<ResultsWithPageData<List<Org>>> {
  const options: FetchOptions = {
    apiRoot: `${usersApiRoot}/orgs`,
    key: 'orgs',
    query,
    useOrgId: false,
    clazz: Org,
  };

  if (setInState) {
    options.setFn = setOrgs;
    options.statePath = ['orgs', 'orgs'];
  }

  return (store: ThreekitStore) => fetchResources<List<Org>>(store, options);
}

export function fetchOrgs(opts: FetchOrgsOpts = {}): ThunkAction<boolean> {
  return async (store: ThreekitStore) => {
    const q = opts.showAll
      ? '?' + queryString.stringify({ showAll: true })
      : '';
    const res = await store.dispatch(
      cacheFetch('2h', `${usersApiRoot}/orgs${q}`, {})
    );

    if (res === true) return Promise.resolve(true);
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setOrgs(res.orgs));
    return Promise.resolve(true);
  };
}

export function fetchOrg(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.dispatch(
      cacheFetch('2h', `${usersApiRoot}/orgs/${id}`, {})
    );
    if (res === true) return Promise.resolve(true);
    if (res.error) return Promise.reject(res.error);

    store.dispatch(addOrg(res));
    return Promise.resolve(res);
  };
}

export function removeMember(orgId: string, memberId: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${usersApiRoot}/orgs/${orgId}/members/${memberId}`,
      method: 'DELETE',
    });
    await store.dispatch(setOrg(res));
    return true;
  };
}

export function setInvites(userInvites: UserInviteProps[]) {
  return { type: Actions.SET_INVITES, payload: userInvites };
}
export function setInvite(userInvite: UserInviteProps) {
  return { type: Actions.SET_INVITE, payload: userInvite };
}
export function addInvite(userInvite: UserInviteProps) {
  return { type: Actions.ADD_INVITE, payload: userInvite };
}
export function getInvite(store: ThreekitStore, id: string): UserInvite {
  return store.getIn(['orgs', 'invites', id]);
}
export function getInvites(
  store: ThreekitStore,
  orgId?: string
): UserInvitesMap {
  const userInvites = store.getIn(['orgs', 'invites']);
  return orgId
    ? userInvites.filter((invite: UserInvite) => invite.orgId === orgId)
    : userInvites;
}

export interface sendUserInvite {
  email: string;
  orgId: string;
  orgName: string;
}

export function createInvite(body: sendUserInvite) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${userInvitesApiRoot}/invites`,
      body,
      method: 'POST',
    });
    await store.dispatch(addInvite(res));
    return res;
  };
}

export function fetchInvites(orgId?: string) {
  return async (store: ThreekitStore) => {
    const q = orgId ? '?' + queryString.stringify({ orgId }) : '';
    const res = await store.callApi({
      url: `${userInvitesApiRoot}/invites${q}`,
      method: 'GET',
    });
    await store.dispatch(setInvites(res.invites));
  };
}

export function redeemInvite(inviteId: string, userId: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${userInvitesApiRoot}/invites/${inviteId}`,
      method: 'PUT',
      body: { userId },
    });
    return res.orgId;
  };
}

export function cancelInvite(inviteId: string | string[] | undefined | null) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${userInvitesApiRoot}/invites/${inviteId}`,
      method: 'DELETE',
    });
    store.dispatch(setInvite(res));

    return Promise.resolve(res.id);
  };
}

export function setTokens(tokens: TokenProps[]) {
  return { type: Actions.SET_TOKENS, payload: tokens };
}

export function setToken(token: TokenProps) {
  return { type: Actions.SET_TOKEN, payload: token };
}

function keyIn(...keys: any[]) {
  const keySet = Set(keys);
  return (v: any, k: any) => keySet.has(k);
}

const publicApi = {
  reducer,
  actions: {},
  selectors: { getOrgs },
  records: [Org, OrgState],
  persist: (state: OrgState) =>
    Map(state)
      .filter(keyIn('activeOrg'))
      .toJS(),
};

export function addToken(token: TokenProps) {
  return { type: Actions.ADD_TOKEN, payload: token };
}

export function getToken(store: ThreekitStore, id: string): Token {
  return store.getIn(['orgs', 'tokens', id]);
}

export function getTokens(store: ThreekitStore, orgId?: string): TokensMap {
  const tokens = store.getIn(['orgs', 'tokens']);
  return orgId
    ? tokens.filter((token: Token) => token.orgId === orgId)
    : tokens;
}

export function fetchTokens(orgId?: string) {
  return async (store: ThreekitStore) => {
    const q = orgId ? '?' + queryString.stringify({ orgId }) : '';
    const res = await store.callApi({
      url: `${accessTokensApiRoot}/accesstokens${q}`,
      method: 'GET',
    });
    if (res === true) {
      return Promise.resolve(true);
    }
    store.dispatch(setTokens(res.accesstokens));

    return Promise.resolve(true);
  };
}

export function fetchToken(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${accessTokensApiRoot}/accesstokens/${id}`,
      method: 'GET',
    });
    if (res.error) {
      return Promise.reject(res);
    }
    store.dispatch(setInvite(res));

    return Promise.resolve(true);
  };
}

export interface createTokenProps {
  name: string;
  permissions: string;
  domains?: string[];
}
export function createToken(body: createTokenProps) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${accessTokensApiRoot}/accesstokens`,
      body,
      method: 'POST',
    });

    if (res.error) {
      return Promise.reject(res);
    }
    store.dispatch(addToken(res));

    return Promise.resolve(true);
  };
}

export function deleteToken(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${accessTokensApiRoot}/accesstokens/${id}`,
      method: 'DELETE',
    });
    if (res.error) {
      return res.error;
    }
    store.dispatch(setInvite(res));

    return Promise.resolve(res.id);
  };
}

export function addWebhook(webhook: WebhookProps) {
  return { type: Actions.ADD_WEBHOOK, payload: webhook };
}

export function addWebhookRequest(webhookRequest: WebhookRequestProps) {
  return { type: Actions.ADD_WEBHOOK_REQUEST, payload: webhookRequest };
}

export function setWebhookRequests(webhookrequests: WebhookRequestProps) {
  return { type: Actions.SET_WEBHOOK_REQUESTS, payload: webhookrequests };
}

export function setWebhooks(webhooks: WebhookProps[]) {
  return { type: Actions.SET_WEBHOOKS, payload: webhooks };
}

export function setWebhook(webhook: WebhookProps) {
  return { type: Actions.SET_WEBHOOK, payload: webhook };
}

export function delWebhook(id: string) {
  return { type: Actions.DEL_WEBHOOK, payload: id };
}
export interface createWebhookProps {
  orgId: string;
  topic: string;
  address: string;
}

export function createWebhook(body: createWebhookProps) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${webhooksApiRoot}/webhooks`,
      body,
      method: 'POST',
    });
    if (res.error) {
      return Promise.reject(res);
    }
    store.dispatch(addWebhook(res));

    return Promise.resolve(true);
  };
}

export function updateWebhook(props: WebhookProps): ThunkAction<string> {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${webhooksApiRoot}/webhooks/${props.id}`,
      body: props,
      method: 'PUT',
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setWebhook(res));
    return Promise.resolve(res.id);
  };
}

export function deleteWebhook(id: string) {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${webhooksApiRoot}/webhooks/${id}`,
      method: 'DELETE',
    });
    if (res.error) {
      return res.error;
    }
    store.dispatch(delWebhook(id));

    return Promise.resolve(res.id);
  };
}

export interface WebHookQuery {
  orgId?: string;
  status?: string;
}

export function fetchWebhooks(
  query?: PaginationQuery & WebHookQuery
): ThunkAction<ResultsWithPageData<List<Webhook>>> {
  return async (store: ThreekitStore) => {
    const res = await fetchResources<List<Webhook>>(store, {
      apiRoot: `${webhooksApiRoot}/webhooks`,
      key: 'webhooks',
      setFn: setWebhooks,
      statePath: ['orgs', 'webhooks'],
      query,
      clazz: Webhook,
    });
    const results = await Promise.all(
      res.results.map(async (webhook: Webhook) => {
        const user = await store.dispatch(getOrFetchUser(webhook.createdBy!));
        return webhook.set('user', user && user.username);
      })
    );
    res.results = List(results);
    return res;
  };
}

export function fetchWebhookRequests(
  query: PaginationQuery & WebHookQuery = {}
): ThunkAction<ResultsWithPageData<List<WebhookRequest>>> {
  return async (store: ThreekitStore) => {
    const { orgId } = getActiveOrg(store);
    query.orgId = orgId;
    const res = await fetchResources<List<WebhookRequest>>(store, {
      apiRoot: `${webhooksApiRoot}/webhooks/requests`,
      key: 'webhookrequests',
      setFn: setWebhookRequests,
      statePath: ['orgs', 'webhookRequests'],
      query,
      clazz: WebhookRequest,
    });
    const results = res.results.map((webhookRequest: WebhookRequest) => {
      const msg = deriveResultMessage(webhookRequest);
      return webhookRequest.set('statusMsg', msg);
    });

    res.results = List(results);
    return res;
  };
}

export function fetchWebhookRequest(id: string) {
  return async (store: ThreekitStore) => {
    const url = `${webhooksApiRoot}/webhooks/requests/${id}`;
    const res = await store.callApi({ url });
    store.dispatch(addWebhookRequest(res));
    return res;
  };
}

export function retryWebhookRequest(id: string) {
  return async (store: ThreekitStore) => {
    const { orgId } = getActiveOrg(store);
    const url = `${webhooksApiRoot}/webhooks/requests/${id}/retry`;
    const res = await store.callApi({ url, method: 'POST', body: { orgId } });
    store.dispatch(addWebhookRequest(res));
    return res;
  };
}

export default publicApi;

function deriveResultMessage(webhookRequest: WebhookRequest) {
  const { status, updatedAt, response = [], id, retry } = webhookRequest;
  const latestRes = response.pop();
  const code = (latestRes && latestRes.code) || 200;
  if (status === 'ok')
    return {
      code,
      message: `Delivered${retry > 0 ? ` (${retry + 1} attempts) ` : ''}`,
      id,
    };
  if (status === 'failed' || retry >= 5) {
    return {
      code,
      message: `Failed ${retry + 1} time${retry === 0 ? '' : 's'}`,
      id,
    };
  }

  const retryPolicy: { [key: number]: number } = {
    0: 5 * 60 * 1000,
    1: 60 * 60 * 1000,
    2: 12 * 60 * 60 * 1000,
    3: 24 * 60 * 60 * 1000,
    4: 2 * 24 * 60 * 60 * 1000,
  };

  const retryCount =
    retryPolicy[retry] - (new Date().getTime() - new Date(updatedAt).getTime());
  return {
    code,
    message: `Failed ${retry + 1} time${retry === 0 ? '' : 's'}`,
    id,
    retryCount,
  };
}
