"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _isEqualWith2 = _interopRequireDefault(require("lodash/isEqualWith"));

var _react = _interopRequireDefault(require("react"));

var customizer = function customizer(obj, other) {
  if (obj === other) return true;

  if (!obj && !other) {
    var objIsEmpty = obj === null || obj === undefined || obj === '';
    var otherIsEmpty = other === null || other === undefined || other === '';
    return objIsEmpty === otherIsEmpty;
  }

  if (obj && other && obj._error !== other._error) return false;
  if (obj && other && obj._warning !== other._warning) return false;
  if (_react["default"].isValidElement(obj) || _react["default"].isValidElement(other)) return false;
};

var deepEqual = function deepEqual(a, b) {
  return (0, _isEqualWith2["default"])(a, b, customizer);
};

var _default = deepEqual;
exports["default"] = _default;