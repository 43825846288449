// Fork of: https://github.com/scottcorgan/tiny-emitter

/**
 * Event Emitter
 * @mixin
 * @public
 */
module.exports = function eventEmitter() {
  const e = {};

  /**
   *
   * Subscribe to an event.
   *
   * @param {string} name
   * @param {function} callback
   * @param {any} [context]
   * @public
   * @instance
   * @memberof eventEmitter
   */
  function on(name, callback, context) {
    (e[name] || (e[name] = [])).push({
      fn: callback,
      ctx: context,
    });
    return this;
  }

  /**
   *
   * Subscribe to an event only once.
   *
   * @param {string} name
   * @param {function} callback
   * @param {any} [context]
   * @memberof eventEmitter
   * @instance
   * @public
   */
  function once(name, callback, context) {
    function listener() {
      off(name, listener);
      callback.apply(context, arguments);
    }

    listener._ = callback;

    return on(name, listener, context);
  }

  /**
   *
   * Emit an event
   *
   * @param {string} name
   * @param {any} ...rest arguments for the event
   * @memberof eventEmitter
   * @instance
   * @public
   */
  function emit(name) {
    const data = [].slice.call(arguments, 1);
    const evtArr = (e[name] || []).slice();
    let i = 0;
    const len = evtArr.length;

    for (i; i < len; i++) {
      evtArr[i].fn.apply(evtArr[i].ctx, data);
    }

    return this;
  }

  /**
   * Unsubscribe from an event or all events.
   * If no callback is provided, it unsubscribes all callback from this event.
   *
   * @param {string} name
   * @param {function} callback
   * @memberof eventEmitter
   * @instance
   * @public
   */
  function off(name, callback) {
    const evts = e[name];
    const liveEvents = [];

    if (evts && callback) {
      for (var i = 0, len = evts.length; i < len; i++) {
        if (evts[i].fn !== callback && evts[i].fn._ !== callback)
          liveEvents.push(evts[i]);
      }
    }

    liveEvents.length ? (e[name] = liveEvents) : delete e[name];

    return this;
  }

  return { on, once, emit, off };
};
