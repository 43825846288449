declare global {
  var threekitconf: any;
  interface Window {
    threekitconf: any;
  }
}

/*! @preserve replaceme with threekitconf
 *
 replaceme with threekitconf end*/

export const threekitEnv = threekitconf.threekitEnv;
export const accessTokensApiRoot = threekitconf.accessTokensApiRoot;
export const casApiRoot = threekitconf.casApiRoot;
export const appRoot = threekitconf.appRoot;
export const assetsApiRoot = threekitconf.assetsApiRoot;
export const usersApiRoot = threekitconf.usersApiRoot;
export const filesApiRoot = threekitconf.filesApiRoot;
export const catalogApiRoot = threekitconf.catalogApiRoot;
export const filesBucketRoot = threekitconf.filesBucketRoot;
export const projectsApiRoot = threekitconf.projectsApiRoot;
export const jobsApiRoot = threekitconf.jobsApiRoot;
export const tasksApiRoot = threekitconf.tasksApiRoot;
export const userInvitesApiRoot = threekitconf.userInvitesApiRoot;
export const productsApiRoot = threekitconf.productsApiRoot;
export const commentsApiRoot = threekitconf.commentsApiRoot;
export const notificationsApiRoot = threekitconf.notificationsApiRoot;
export const activitiesApiRoot = threekitconf.activitiesApiRoot;
export const assetJobsApiRoot = threekitconf.assetJobsApiRoot;
export const agGridKey = threekitconf.agGridKey;
export const rollbarToken = threekitconf.rollbarToken;
export const googleAnalyticsToken = threekitconf.googleAnalyticsToken;
export const productImportApiRoot = threekitconf.productImportApiRoot;
export const ordersApiRoot = threekitconf.ordersApiRoot;
export const webhooksApiRoot = threekitconf.webhooksApiRoot;
export const layersApiRoot = threekitconf.layersApiRoot;
export const customersAPiRoot = threekitconf.customersApiRoot;
export const configurationsApiRoot = threekitconf.configurationsApiRoot;
export const appsApiRoot = threekitconf.appsApiRoot;
export const interstitialApiRoot = threekitconf.interstitialApiRoot;
