import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { List, Record, OrderedMap } from 'immutable';
import _ from 'lodash';
import { cacheFetch } from 'sections/app/cache';
import {
  fetchResources,
  PaginationQuery,
  ResultsWithPageData,
} from 'sections/app/pagination';
import { customersAPiRoot } from '../../conf';
import { fetchOrders } from 'sections/orders/orders';

interface CustomerProps {
  id: string;
  orgId: string;
  position: string;
  city: string;
  name: string;
  country: string;
  email: string;
  phone: string;
  ip: string;
  orders: any;
  metadata: { [name: string]: any };
  platform: { [name: string]: any };
  createdAt: Date;
}

const defaultCustomerProps = {
  id: '',
  orgId: '',
  position: '',
  name: '',
  city: '',
  country: '',
  email: '',
  phone: '',
  ip: '',
  orders: [],
  metadata: {},
  platform: {},
  createdAt: new Date(),
};

export class Customer extends Record(defaultCustomerProps)
  implements CustomerProps {}

interface CustomerStateProps {
  customers: OrderedMap<string, Customer>;
}

type CustomerStateType = Record<CustomerStateProps>;

const defaultCustomerStateProps: CustomerStateProps = {
  customers: OrderedMap(),
};

export class CustomerState extends Record(defaultCustomerStateProps)
  implements CustomerStateProps {}

const initialState = new CustomerState();

const enum Actions {
  SET_CUSTOMER = 'SET_CUSTOMER',
  SET_CUSTOMERS = 'SET_CUSTOMERS',
}

const reducer = {
  initialState,
  [Actions.SET_CUSTOMER](state: CustomerStateType, payload: CustomerProps) {
    return state.setIn(['customers', payload.id], payload);
  },

  [Actions.SET_CUSTOMERS](state: CustomerStateType, payload: CustomerProps[]) {
    const customers = payload.reduce(
      (acc: OrderedMap<string, Customer>, customer: CustomerProps) => {
        return acc.set(customer.id, new Customer(customer));
      },
      state.get('customers')
    );
    return state.set('customers', customers);
  },
};

export function fetchCustomers(
  query?: PaginationQuery
): ThunkAction<ResultsWithPageData<List<Customer>>> {
  return async (store: ThreekitStore) => {
    const data = await fetchResources<List<Customer>>(store, {
      apiRoot: `${customersAPiRoot}/customers`,
      key: 'customers',
      clazz: Customer,
      setFn: setCustomers,
      statePath: ['customers', 'customers'],
      query,
    });
    const fetchOrderForCustomer = data.results.map(async (customer: any) => {
      const orders = await store.dispatch(
        fetchOrders({ customerId: customer.id })
      );
      customer = customer.set('orders', orders.results);
      return customer;
    });
    const results = await Promise.all(fetchOrderForCustomer);
    data.results = List(results);
    return data;
  };
}

export function fetchCustomer(id: string) {
  return async (store: ThreekitStore) => {
    const customer = getCustomer(store, id);
    if (customer) return Promise.resolve(customer);
    const res = await store.dispatch(
      cacheFetch('2h', `${customersAPiRoot}/customers/${id}`, {})
    );
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setCustomer(res));
    return Promise.resolve(getCustomer(store, id));
  };
}

export function getCustomer(store: ThreekitStore, id: string): Customer {
  return store.getIn(['customers', 'customers', id]);
}

export function getCustomers(
  store: ThreekitStore,
  query: any = {}
): List<Customer> {
  const { orgId } = query;
  return List(
    store
      .getIn(['customers', 'customers'])
      .filter(
        (customer: Customer) => (orgId && orgId === customer.orgId) || true
      )
      .values()
  );
}

function setCustomers(customers: CustomerProps[]) {
  return { type: Actions.SET_CUSTOMERS, payload: customers };
}

function setCustomer(customer: CustomerProps) {
  return { type: Actions.SET_CUSTOMER, payload: customer };
}

const publicApi = {
  reducer,
  actions: {},
  selectors: {},
};

export default publicApi;
