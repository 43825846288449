import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { ordersApiRoot } from 'conf';
import { List, Map, Record } from 'immutable';
import _ from 'lodash';
import {
  FetchOptions,
  fetchResources,
  PaginationQuery,
  ResultsWithPageData,
} from 'sections/app/pagination';
import { cacheFetch } from 'sections/app/cache';
import { Asset, fetchAssetsById, getAsset } from 'sections/assets/assets';
import {
  Configuration,
  fetchConfigurationsById,
} from 'sections/configurations/configurations';
import { fetchCustomer } from 'sections/customers/customers';

interface PlatformDetails {
  platform?: string;
  id?: any;
  storeName?: string;
}

export interface OrderProps {
  id: string;
  shortId: string;
  orgId: string;
  customerId: string;
  customerName: string;
  originOrgId: string;
  platform: PlatformDetails;
  metadata: { [key: string]: any };
  items: any[];
  derivative: { [key: string]: any };
  status: string;
  createdAt: Date;
  configurations: Map<string, Configuration>;
  assets: Map<string, Asset>;
}

const defaultOrderProps: OrderProps = {
  id: '',
  shortId: '',
  orgId: '',
  customerId: '',
  customerName: '',
  originOrgId: '',
  platform: {},
  metadata: {},
  items: [],
  derivative: {},
  status: '',
  createdAt: new Date(),
  configurations: Map(),
  assets: Map(),
};

export interface OrderQuery {
  id?: string;
  shortId?: string;
  orgId?: string;
  originOrgId?: string;
  customerId?: string;
  platform?: { [key: string]: any };
}

export class Order extends Record(defaultOrderProps) implements OrderProps {}

function inflateOrder(
  store: ThreekitStore,
  order: Order,
  configurations: Configuration[]
) {
  let newOrder = order;
  for (const item of order.items) {
    // Orders api uses configuration shortIds as well, use passed in configurations rather than redux state which uses regular ids
    const configuration = configurations.find(
      config => config.shortId === item.id || config.id === item.id
    );

    if (!configuration) return order;
    const asset = getAsset(store, configuration.productId);

    newOrder = newOrder
      .setIn(['configurations', item.id], configuration)
      .setIn(['assets', asset.id], asset);
  }

  return newOrder;
}

async function fetchDependencies(store: ThreekitStore, orders: List<Order>) {
  // Get list of configurations that need to be fetched
  const configIds = orders.reduce(
    (acc, order) => {
      for (const item of order.items) {
        acc.push(item.id);
      }
      return acc;
    },
    [] as string[]
  );
  const configurations = await store.dispatch(
    fetchConfigurationsById(configIds)
  );

  // With the fetched configurations, get the list of products that need to be fetched
  const assetIds = configurations.reduce(
    (acc, configuration) => {
      acc.push(configuration.productId);
      return acc;
    },
    [] as string[]
  );

  await store.dispatch(fetchAssetsById(assetIds));

  // Translate customerId

  const fetchCustomers = orders.map(async order => {
    if (order.customerId) {
      const customer = await store.dispatch(fetchCustomer(order.customerId));
      order = order.set('customerName', customer && customer.name);
    }
    return order;
  });
  const updatedOrders = await Promise.all(fetchCustomers);
  return List(updatedOrders).map((order, key) =>
    inflateOrder(store, order, configurations)
  );
}

export function fetchOrders(
  query: PaginationQuery & OrderQuery = {}
): ThunkAction<ResultsWithPageData<List<Order>>> {
  const options: FetchOptions = {
    apiRoot: `${ordersApiRoot}/orders`,
    key: 'orders',
    clazz: Order,
    query,
  };

  return async (store: ThreekitStore) => {
    const orders = await fetchResources<List<Order>>(store, options);
    orders.results = await fetchDependencies(store, orders.results);
    return orders;
  };
}

export function fetchOrder(orderId: string): ThunkAction<Order> {
  return async (store: ThreekitStore) => {
    const url = `${ordersApiRoot}/orders/${orderId}`;
    const res = await store.dispatch(cacheFetch('2h', url, {}));
    if (res.error) return Promise.reject(res.error);
    const orderList = await fetchDependencies(store, List([new Order(res)]));
    return Promise.resolve(orderList.first());
  };
}

const publicApi = {};

export default publicApi;
