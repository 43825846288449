const browser = require('detect-browser');

/*
 * This module wraps the detect-browser library, giving it a better
 * api (So it's not undefined if no browser is matched), and also
 * contains our own checks for `isMobile`, `isIE`, `isIOS`, etc.
 */
const info = browser || { name: 'unknown', version: '0' };
info.isIE = info.name === 'ie';
info.isMobile = false;
info.isIOS = false;
info.isAndroid = false;
info.isAndroidTablet = false;
info.isMac = false;

if (__BROWSER__ && window.navigator) {
  const userAgent = window.navigator.userAgent;
  info.isIOS = /iPad|iPhone|iPod/.test(userAgent);
  info.isAndroid = /Android/i.test(userAgent);
  const isMSPhone = /IEMobile|Windows Phone|Lumia/i.test(userAgent);
  const isChromebook = /CrOS/i.test(userAgent);
  const isBlackberry = /Blackberry|webOS/i.test(userAgent);
  info.isMac = /Mac/i.test(userAgent);
  info.isMobile =
    info.isIOS || info.isAndroid || isMSPhone || isChromebook || isBlackberry;
  info.isAndroidTablet = info.isAndroid && /^(?!.*Mobile).*$/.test(userAgent);
  // window.runtimeInfo = info;
}

module.exports = info;
