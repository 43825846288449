import { ThreekitStore } from '@threekit/redux-store';
import { notificationsApiRoot } from 'conf';
import { Map, Record } from 'immutable';
import queryString from 'query-string';
import { cacheFetch } from 'sections/app/cache';

export interface NotificationProps {
  id: string;
  type: string;
  content: string;
  parentId: string;
  createdBy: string;
  orgId?: string;
  projectId?: string;
  createdAt?: Date;
}

const defaultNotificationProps = {
  id: '',
  content: '',
  type: '',
  attachments: [],
  orgId: '',
  parentId: '',
  createdBy: '',
  projectId: '',
  createdAt: new Date(),
};

export class Notification extends Record(defaultNotificationProps)
  implements NotificationProps {}

interface NotificationStateProps {
  pages: Map<string, Notification[]>;
  count: number;
}

type NotificationStateType = Record<NotificationStateProps>;

const defaultNotificationStateProps: NotificationStateProps = {
  pages: Map(),
  count: 0,
};

export class NotificationState extends Record(defaultNotificationStateProps)
  implements NotificationStateProps {}

const initialState = new NotificationState();

const enum Actions {
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
}

interface NotificationStateProps {
  pages: Map<string, Notification[]>;
}

interface SetNotificationsProps {
  page: number;
  count: number;
  notifications: Notification[];
}

const reducer = {
  initialState,
  [Actions.SET_NOTIFICATIONS](
    state: NotificationStateType,
    payload: SetNotificationsProps
  ) {
    return state
      .setIn(['pages', payload.page], payload.notifications)
      .set('count', payload.count);
  },
};

function setNotifications(
  page: number,
  notifications: Notification[],
  count: number
) {
  return {
    type: Actions.SET_NOTIFICATIONS,
    payload: { page, notifications, count },
  };
}

export function getNotifications(
  store: ThreekitStore,
  query: NotificationQuery
) {
  const page = query.page || 0;
  return store.getIn(['notifications', 'pages', page]);
}

export function getNotificationCount(store: ThreekitStore) {
  return store.getIn(['notifications', 'count']);
}

interface NotificationQuery {
  createdBy?: string;
  orgId?: string;
  projectId?: string;
  page?: number;
  perPage?: number;
}

export function fetchNotifications(
  query: NotificationQuery = { page: 0, perPage: 50 }
) {
  // console.log(notificationsApiRoot);
  return async (store: ThreekitStore) => {
    const res = await store.dispatch(
      cacheFetch(
        '2h',
        `${notificationsApiRoot}/notifications?${queryString.stringify(query)}`,
        query
      )
    );
    if (res === true) return Promise.resolve(true);
    if (res.error) return Promise.reject(res.error);
    store.dispatch(
      setNotifications(query.page || 0, res.notifications, Number(res.count))
    );
    return Promise.resolve(true);
  };
}

const publicApi = {
  reducer,
  actions: {},
  selectors: {},
};

export default publicApi;
