import { setContentStore } from '@threekit/cas';
import { Command, Player } from '@threekit/hub-player';
import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { scene, sceneGraph } from '@threekit/scene-graph';
import {
  assetJobsApiRoot,
  assetsApiRoot,
  casApiRoot,
  catalogApiRoot,
  filesApiRoot,
  productImportApiRoot,
} from 'conf';
import { fromJS, List, Map, Record } from 'immutable';
import history from 'lib/history';
import _ from 'lodash';
import queryString from 'query-string';
import { cacheFetch } from 'sections/app/cache';
import {
  FetchOptions,
  fetchResources,
  PaginationQuery,
  reflectPromises,
  ResultsWithPageData,
} from 'sections/app/pagination';
import { getUser } from 'sections/auth/user';
import { getActiveOrg } from 'sections/orgs/orgs';
import uuid from 'uuid';

export interface AssetStatus {
  staging?: string;
  production?: string;
}

export type AssetType =
  | 'scene'
  | 'model'
  | 'material'
  | 'texture'
  | 'item'
  | 'folder'
  | 'font'
  | 'vector'
  | 'vfb'
  | 'stage';
export type ExportType = 'glb' | 'gltf' | 'usdz' | 'fbx';

export interface AssetProps {
  id: string;
  name: string;
  description: string;
  type: AssetType;
  orgId: string;
  tags: List<string>;
  keywords: List<string>;
  createdAt: Date;
  publicShare?: boolean;
  parentFolderId?: string;
  metadata?: string | { [key: string]: any };
  proxyId?: string;
}

const defaultAssetProps: AssetProps = {
  id: '',
  name: '',
  description: '',
  createdAt: new Date(),
  type: 'scene',
  orgId: '',
  tags: List() as List<string>,
  keywords: List() as List<string>,
  publicShare: false,
  parentFolderId: '',
  proxyId: undefined,
};

export interface AssetQuery {
  type?: AssetType | AssetType[];
  name?: string;
  ids?: string[];
  orgId?: string;
  parentFolderId?: string;
  proxyId?: string;
  all?: boolean;
}

export class Asset extends Record(defaultAssetProps) implements AssetProps {}

interface AssetStateProps {
  assets: Map<string, Asset>;
  tags: List<string>;
  keywords: List<string>;
  tempId: string;
}
type AssetStateType = Record<AssetStateProps>;

const defaultAssetStateProps: AssetStateProps = {
  assets: Map(),
  tags: List() as List<string>,
  keywords: List() as List<string>,
  tempId: '',
};

export class AssetState
  extends Record(defaultAssetStateProps)
  implements AssetStateProps {}
const initialState = new AssetState();

const enum Actions {
  ADD_ASSET = 'ADD_ASSET',
  DEL_ASSET = 'DEL_ASSET',
  SET_ASSET = 'SET_ASSET',
  SET_ASSETS = 'SET_ASSETS',
  SET_TAGS = 'SET_TAGS',
  UPDATE_TAGS = 'UPDATE_TAGS',
  SET_KEYWORDS = 'SET_KEYWORDS',
  UPDATE_KEYWORDS = 'UPDATE_KEYWORDS',
  SET_TEMP_ID = 'SET_TEMP_ID',
}

function inflateAsset(props: AssetProps) {
  return new Asset(props).set('tags', List(props.tags));
}

const reducer = {
  initialState,
  [Actions.SET_ASSETS](state: AssetStateType, assets: AssetProps[]) {
    const prevAssets = state.get('assets');
    const map = assets.reduce((acc: Map<string, Asset>, props: AssetProps) => {
      return acc.set(props.id, inflateAsset(props));
    }, prevAssets);
    return state.set('assets', map);
  },
  [Actions.SET_ASSET](state: AssetStateType, props: AssetProps) {
    return state.setIn(['assets', props.id], inflateAsset(props));
  },
  [Actions.ADD_ASSET](state: AssetStateType, props: AssetProps) {
    return state.setIn(['assets', props.id], inflateAsset(props));
  },
  [Actions.DEL_ASSET](state: AssetStateType, id: string) {
    return state.deleteIn(['assets', id]);
  },
  [Actions.SET_TAGS](state: AssetStateType, tags: string[]) {
    return state.set('tags', fromJS(tags));
  },
  [Actions.UPDATE_TAGS](state: AssetStateType, tags: string[]) {
    const combinedTags = state
      .get('tags')
      .toSet()
      .union<string>(fromJS(tags))
      .toList();
    return state.set('tags', combinedTags);
  },

  [Actions.SET_KEYWORDS](state: AssetStateType, keywords: string[]) {
    return state.set('keywords', fromJS(keywords));
  },

  [Actions.UPDATE_KEYWORDS](state: AssetStateType, keywords: string[]) {
    const combinedKeywords = state
      .get('keywords')
      .toSet()
      .union<string>(fromJS(keywords))
      .toList();
    return state.set('keywords', combinedKeywords);
  },
  [Actions.SET_TEMP_ID](state: AssetStateType, id: string) {
    return state.set('tempId', id);
  },
};

export function setAssets(assets: AssetProps[]) {
  return { type: Actions.SET_ASSETS, payload: assets };
}
export function setAsset(asset: AssetProps) {
  return { type: Actions.SET_ASSET, payload: asset };
}

function updateTags(tags: string[]) {
  return { type: Actions.UPDATE_TAGS, payload: tags };
}

function setTags(tags: string[]) {
  return { type: Actions.SET_TAGS, payload: tags };
}

function updateKeywords(keywords: string[]) {
  return { type: Actions.UPDATE_KEYWORDS, payload: keywords };
}

function setKeywords(keywords: string[]) {
  return { type: Actions.SET_KEYWORDS, payload: keywords };
}

export function addAsset(asset: AssetProps) {
  return { type: Actions.ADD_ASSET, payload: asset };
}

export function getAsset(store: ThreekitStore, id: string): Asset {
  return store.getIn(['assets', 'assets', id]);
}
export function getAssets(store: ThreekitStore, orgId: string): List<Asset> {
  return List(
    store
      .getIn(['assets', 'assets'])
      .filter((asset: Asset) => asset.orgId === orgId)
      .filter((asset: Asset) => asset.type !== 'item')
      .values()
  );
}

export function getProducts(store: ThreekitStore, orgId: string) {
  return List(
    store
      .getIn(['assets', 'assets'])
      .filter((asset: Asset) => asset.orgId === orgId)
      .filter((asset: Asset) => asset.type === 'item')
      .values()
  );
}

export function getStages(store: ThreekitStore, orgId: string) {
  return List(
    store
      .getIn(['assets', 'assets'])
      .filter((asset: Asset) => asset.orgId === orgId)
      .filter((asset: Asset) => asset.type === 'stage')
      .values()
  );
}

export function getTags(store: ThreekitStore, query: { orgId?: string } = {}) {
  const tags = store.getIn(['assets', 'tags']);

  return List(tags.values());
}

export function getKeywords(
  store: ThreekitStore,
  query: { orgId?: string } = {}
) {
  const keywords = store.getIn(['assets', 'keywords']);

  return List(keywords.values());
}

export interface CreateAsset {
  id?: string;
  orgId: string;
  parentFolderId?: string | string[] | null;
  createdBy?: string;
  type: string;
  name: string;
  objects?: { [key: string]: string };
  ref?: string;
}

export function createAsset(body: CreateAsset): ThunkAction<string> {
  return async (store: ThreekitStore) => {
    const url = `${assetsApiRoot}/assets`;
    const user = getUser(store);
    body.createdBy = user && user.id;
    const res = await store.callApi({ url, body, method: 'POST' });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(addAsset(res));
    store.dispatch(updateTags(res.tags));
    store.dispatch(updateKeywords(res.keywords));
    return Promise.resolve(res.id);
  };
}

export function cloneAsset(
  assetId: string,
  setProxy: boolean = false
): ThunkAction<AssetProps> {
  return async (store: ThreekitStore) => {
    const { orgId } = getActiveOrg(store);
    const url = `${assetsApiRoot}/assets/${assetId}/clone`;
    const res = await store.callApi({
      url,
      method: 'POST',
      body: { orgId, setProxy },
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(addAsset(res));
    return Promise.resolve(res);
  };
}

export function updateAsset(
  id: string,
  update: Partial<AssetProps>
): ThunkAction<string> {
  return async (store: ThreekitStore) => {
    const res = await store.callApi({
      url: `${assetsApiRoot}/assets/${id}`,
      body: update,
      method: 'PUT',
    });
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setAsset(res));
    store.dispatch(updateTags(res.tags));
    store.dispatch(updateKeywords(res.keywords));
    return Promise.resolve(res.id);
  };
}

export function deleteAsset(assetId: string) {
  return async (store: ThreekitStore) => {
    const { orgId } = getActiveOrg(store);
    const url = `${assetsApiRoot}/assets/${assetId}?orgId=${orgId}`;
    await store.callApi({ url, method: 'DELETE' });
    return store.dispatch({ type: Actions.DEL_ASSET, payload: assetId });
  };
}

export function getOrFetchAsset(assetId: string) {
  return async (store: ThreekitStore) => {
    const asset = getAsset(store, assetId);
    return asset || store.dispatch(fetchAsset(assetId));
  };
}

export function fetchAsset(assetId: string): ThunkAction<Asset> {
  return async (store: ThreekitStore) => {
    const { orgId } = getActiveOrg(store);
    const url = `${assetsApiRoot}/assets/${assetId}?orgId=${orgId}`;
    const res = await store.dispatch(cacheFetch('2h', url, {}));
    if (res.error) return Promise.reject(res.error);
    store.dispatch(setAsset(res));
    return Promise.resolve(getAsset(store, assetId));
  };
}

export const fetchAssetsById = (ids: string[]) => async (
  store: ThreekitStore
) => {
  if (!ids || !ids.length) {
    return [];
  }

  // Ensure unique ids
  ids = _.uniq(ids);

  const { orgId } = getActiveOrg(store);

  const promises: Array<Promise<any>> = [];

  for (const id of ids) {
    if (!id) {
      continue;
    }
    if (id[0] === '#') {
      promises.push(
        fetchResources<List<Asset>>(store, {
          apiRoot: `${assetsApiRoot}/assets`,
          key: 'assets',
          clazz: Asset,
          query: {
            orgId,
            tags: [id.slice(1)],
            all: true,
          },
        })
      );
    } else {
      promises.push(store.dispatch(fetchAsset(id)));
    }
  }

  return reflectPromises<Asset>(promises);
};

export function fetchAssets(
  query: PaginationQuery & AssetQuery = {},
  setInState: boolean = true
): ThunkAction<ResultsWithPageData<List<Asset>>> {
  const options: FetchOptions = {
    apiRoot: `${assetsApiRoot}/assets`,
    clazz: Asset,
    key: 'assets',
    query,
  };

  if (setInState) {
    options.setFn = setAssets;
    options.statePath = ['assets', 'assets'];
  }

  return (store: ThreekitStore) => fetchResources<List<Asset>>(store, options);
}

export { FileModel } from '@threekit/types';

export function uploadFiles(files: File[]) {
  return async (store: ThreekitStore) => {
    const { orgId } = getActiveOrg(store);
    const data = new FormData();
    data.append('orgId', orgId);
    for (const file of files) {
      data.append(`files`, file);
    }
    const res = await store.callApi({
      url: `${filesApiRoot}/files`,
      body: data,
      method: 'POST',
    });
    return res.files;
  };
}

export const uploadAsset = (files: File[]) => async (store: ThreekitStore) => {
  const state = store.getState();
  const { orgId } = getActiveOrg(state);

  const response = await store.callApi({
    url: `${catalogApiRoot}/catalog/assets/upload?orgId=${orgId}`,
    files: files.map((file) => ({ name: 'files', file })),
    method: 'POST',
  });

  const { jobId } = response[0];

  return new Promise<string>((resolve) => {
    const interval = setInterval(async () => {
      const { output } = await store.callApi({
        url: `${catalogApiRoot}/catalog/jobs/${jobId}?orgId=${orgId}`,
      });

      if (!!output) {
        clearInterval(interval);
        resolve(output.texture[0].assetId);
      }
    }, 5000);
  });
};

export function exportScene(assetId: string, ref: string) {
  return async (store: ThreekitStore) => {
    const body = {
      assetId,
      ref,
    };
    const res = await store.callApi({
      url: `${casApiRoot}/cas/${assetId}/export`,
      body,
      method: 'POST',
    });
    console.log('export result?', res);

    return res.jobId;
  };
}

export const importAsset = (
  fileId: string,
  orgId: string,
  title: string = 'Import',
  assetType: AssetType = 'scene',
  parentFolderId?: string | string[] | null | undefined
) => (store: ThreekitStore) => {
  let url = `${assetJobsApiRoot}/asset-jobs/import`;

  const body: any = {
    fileId,
    orgId,
    title,
    sync: true,
    parentFolderId,
  };

  if (assetType === 'model') {
    body.settings = { vrsceneAsModel: true };
  }

  if (assetType === 'material') {
    url += '/vray-mtl';
  }

  return store.callApi({
    url,
    body,
    method: 'POST',
  });
};

export function exportAsset(
  assetId: string,
  type: ExportType,
  orgId: string,
  options: {
    cache?: string; // use asset cache
    wait?: string; // wait for the latest version
    configuration?: any;
    sync?: boolean;
    useCatalog?: boolean;
    settings?: any;
  } = {}
) {
  return async (store: ThreekitStore) => {
    const activeOrg = getActiveOrg(store);
    const body = {
      ...options,
      assetId,
      orgId: (activeOrg && activeOrg.orgId) || orgId,
    };
    const url = options.useCatalog
      ? `${catalogApiRoot}/catalog/products/${assetId}/export/${type}`
      : `${assetJobsApiRoot}/asset-jobs/${assetId}/export/${type}`;
    const res = await store.callApi({
      url,
      body,
      method: 'POST',
    });
    if (options.sync) {
      if (res.url) {
        // result from cache
        return Promise.resolve(res.url);
      } else {
        // result from job creation
        const job = res.job;
        const run = job.runs && job.runs.pop();
        const fileId = run.results.files[0].id;
        const url = `${filesApiRoot}/files/${fileId}/content`;
        return Promise.resolve(url);
      }
    } else {
      return Promise.resolve(res.jobId);
    }
  };
}

export function renderVray(assetId: string, orgId: string) {
  return async (store: ThreekitStore) => {
    const body = {
      assetId,
      orgId,
      settings: {
        output: {
          format: { type: 'png' },
          resolution: { width: 512, height: 512 },
        },
        renderOptions: {
          renderObjectsIds: false,
          mode: 'cpu',
        },
      },
    };
    const res = await store.callApi({
      url: `${assetJobsApiRoot}/asset-jobs/${assetId}/render/vray/image`,
      body,
      method: 'POST',
    });
    return res.jobId;
  };
}

// products handlers

export function fetchTags(query: { [key: string]: any } = {}) {
  return async (store: ThreekitStore) => {
    const q = queryString.stringify(query);
    const res = await store.dispatch(
      cacheFetch('2h', `${assetsApiRoot}/assets/tags?${q}`, query)
    );

    if (res === true) return Promise.resolve(true);
    if (res.error) return Promise.reject(res.error);

    store.dispatch(setTags(res.tags));
    return Promise.resolve(true);
  };
}

export function fetchKeywords(query: { [key: string]: any } = {}) {
  return async (store: ThreekitStore) => {
    const q = queryString.stringify(query);
    const res = await store.dispatch(
      cacheFetch('2h', `${assetsApiRoot}/assets/keywords?${q}`, query)
    );

    if (res === true) return Promise.resolve(true);
    if (res.error) return Promise.reject(res.error);

    store.dispatch(setKeywords(res.keywords));
    return Promise.resolve(true);
  };
}

export function fetchProductsForTag(
  query: { [key: string]: any } = {}
): ThunkAction<any[]> {
  return async (store: ThreekitStore) => {
    query.type = 'item';
    query.all = true; // no pagination
    const res = await store.callApi({
      url: `${assetsApiRoot}/assets?${queryString.stringify(query)}`,
    });
    return res.assets;
  };
}

export function setTempId(id: string) {
  return { type: Actions.SET_TEMP_ID, payload: id };
}

export function getTempId(store: ThreekitStore): string {
  return store.getIn(['assets', 'tempId']);
}

export function importProduct(files: any) {
  return async (store: ThreekitStore) => {
    const org = getActiveOrg(store);
    const data = new FormData();
    for (const i in files) {
      data.append(files[i].name, files[i].originFileObj);
    }

    const url = `${productImportApiRoot}/products/import?orgId=${org.orgId}`;
    const res = await store.callApi({
      url,
      method: 'POST',
      body: data,
    });
    if (!res || res.message) {
      return Promise.reject(res && res.message);
    }
    let tags: string[] = [];
    let keywords: string[] = [];
    const products = res.products.map((product: any) => {
      tags = tags.concat(product.tags || []);
      keywords = keywords.concat(product.keywords || []);
      return product;
    });
    // store.dispatch(setProducts(products));
    // store.dispatch(updateTags(tags));
    // store.dispatch(updateKeywords(keywords));
    return Promise.resolve(true);
  };
}

export function exportProducts(
  query: { tags?: string; keywords?: string; format?: string } = {}
) {
  return async (store: ThreekitStore) => {
    const org = getActiveOrg(store);
    const format = query.format || 'json';
    const url = `${productImportApiRoot}/products/export/${format}?orgId=${org.orgId}`;
    const res = await store.callApi({ url });
    if (!res || res.message) {
      return Promise.reject(res && res.message);
    }
    const data = JSON.stringify(res) as BlobPart;
    const blob = new Blob([data], {
      type: 'application/json',
    });
    return Promise.resolve(blob);
  };
}

export const assetRedirect = (id: string, slug: string, type: AssetType) =>
  history.push(buildAssetUrl(id, slug, type, 'edit'));

export const buildAssetUrl = (
  id: string,
  slug: string,
  type: AssetType,
  suffix = ''
) =>
  `/o/${slug}/assets/${
    type === 'folder' ? `?parentFolderId=${id}` : `${id}/${suffix}`
  }`;

export function extractVRayMaterial(
  id: string,
  orgId: string,
  parentFolderId?: string | string[] | null | undefined
) {
  return async (store: ThreekitStore) => {
    const body = {
      orgId,
      parentFolderId,
    };
    const res = await store.callApi({
      url: `${assetJobsApiRoot}/asset-jobs/${id}/vray/templates`,
      body,
      method: 'POST',
    });
    return res.jobId;
  };
}

const publicApi = {
  reducer,
  actions: { updateAsset },
  selectors: {},
  records: [Asset, AssetState],
};

const cloneChildren = (
  store: ThreekitStore,
  children: string[],
  parent: string,
  assetId: string
) =>
  Promise.all(
    children.map(async (id) => {
      const node = scene.get(store, id);
      await store.dispatch(
        scene.addNode({ ...node, sceneId: assetId }, parent)
      );
      await cloneChildren(store, node.children, node.id, assetId);
    })
  );

const createCloneCommand = (
  label: string,
  type: 'scene' | 'model'
): Command => ({
  label,
  icon: 'clone',
  ui: ({ selectionSet: { nodes } }: Player) => {
    if (!nodes.length || nodes.length > 1) {
      return false;
    }

    const node = nodes[0];
    return node.type !== 'Material' && node.type !== 'MaterialLibrary';
  },
  exec: async (
    store: ThreekitStore,
    { selectionSet: { nodes }, assetId }: Player
  ) => {
    const asset = getAsset(store, assetId);
    const node = nodes[0];

    const state = store.getState();
    const newAssetId = uuid();

    const { orgId, slug } = getActiveOrg(state);
    const user = getUser(state);

    await store.dispatch(scene.addScene(type, node.name, newAssetId, orgId));

    const objectsContainer = scene.find(state, {
      type: 'Objects',
      from: newAssetId,
    }) as string[];

    const parent = !!objectsContainer ? objectsContainer[0] : newAssetId;

    await cloneChildren(
      store,
      node.type === 'Objects' ? node.children : [node.id],
      parent,
      newAssetId
    );

    const index = await store.dispatch(
      sceneGraph.saveIndex(newAssetId, user.id)
    );

    const { objects, refs } = index;

    await store.dispatch(
      createAsset({
        id: newAssetId,
        parentFolderId: asset.parentFolderId,
        objects,
        ref: refs && refs.INDEX,
        orgId,
        type,
        name: node.name,
      })
    );

    window.open(`/o/${slug}/assets/${newAssetId}/edit`, '_self');
  },
});

export const commands = [
  createCloneCommand('Clone to Scene', 'scene'),
  createCloneCommand('Clone to Model', 'model'),
];

export default publicApi;
