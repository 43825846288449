import { ThreekitStore, ThunkAction } from '@threekit/redux-store';
import { Map, Record } from 'immutable';

interface CacheStateProps {
  queries: Map<string, number>;
}

type CacheStateType = Record<CacheStateProps>;

const defaultCacheStateProps: CacheStateProps = {
  queries: Map(),
};

export class CacheState extends Record(defaultCacheStateProps)
  implements CacheStateProps {}

const initialState = new CacheState();

const enum Actions {
  CACHE_FETCHED = 'CACHE_FETCH',
}

interface CacheQuery {
  key: string;
  timestamp: number;
}

const reducer = {
  initialState,
  [Actions.CACHE_FETCHED](state: CacheStateType, key: string) {
    const timestamp = new Date().getTime();
    return state.setIn(['queries', key], timestamp);
  },
};

const periods: { [index: string]: number } = {
  s: 1,
  m: 60,
  h: 3600,
  d: 3600 * 24,
};

/**
 *
 * @param store {ThreekitStore}
 * @param staleDuration  {string} represents time the cache should be valid for
 * @param queryKey {string} querystring of request
 *
 * The duration is represented using a simple string format, ex:
 * 1s -- 1 second
 * 2m -- 2 minutes
 * 3h -- 3 hours
 * 4d -- 4 days
 */
export function hasCache(
  store: ThreekitStore,
  staleDuration: string,
  queryKey: string
): boolean {
  const queriedAt = store.getIn(['cache', 'queries', queryKey]);
  if (!queriedAt) return false;
  const durationNum = Number(staleDuration.slice(0, staleDuration.length - 1));
  const period = staleDuration[staleDuration.length - 1];
  const cacheDuration = durationNum * periods[period] * 1000;
  const isStale = new Date().getTime() - cacheDuration > queriedAt;
  return !isStale;
}

export function cacheFetch(
  staleDuration: string,
  url: string,
  queryObj: Object
): ThunkAction<any> {
  return async (store: ThreekitStore) => {
    // ***************************************************
    // ** As requested, cache has been disabled for now **
    // ***************************************************
    // if (hasCache(store, staleDuration, url)) return true;
    const result = await store.callApi({
      url,
    });
    // store.dispatch({
    //   type: Actions.CACHE_FETCHED,
    //   payload: url,
    // });
    return result;
  };
}

const publicApi = {
  reducer,
  actions: {},
  selectors: {},
  records: [CacheState],
};

export default publicApi;
