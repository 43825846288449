const element = document.createElement('div');

let initialized = false;

element.className = 'threekit';

export const initializeRootElement = () => {
  if (initialized) {
    return;
  }
  document.body.appendChild(element);
  initialized = true;
};

export const getPopupContainer = (triggerNode?: HTMLElement) => element;

export default element;
